import proxybase from './proxybase';
import jwtDecode from 'jwt-decode';

class authService {
  constructor() {
    this.login = this.login.bind(this);
    this.token = localStorage.getItem('userToken');
  }
  login({ email, password }) {
    return proxybase
      .post('/auth/signin', {
        email: email,
        password: password
      })
      .then(response => {
        return Promise.resolve(response);
      })
      .catch(error => {
        console.log(`An error occurred trying to loged in the user ${error}.`);
        return Promise.reject(error);
      });
  }
  logout() {
    localStorage.removeItem('userToken');
    window.location.replace('/');
  }
  getCurrentUser() {
    try {
      return jwtDecode(this.token);
    } catch {
      return {};
    }
  }
}
export default new authService();
