import React from 'react';
import bgImage from '../../assets/static/cglBackground.png';
import Header from './Header';

const sectionStyles = {};

const Layout = ({ children }) => (
  <section className="App" style={sectionStyles}>
    <Header />
    <main className="Content__wrapper">{children}</main>
  </section>
);

export default Layout;
