import React from 'react';
import QRCode from 'qrcode.react';
import moment from 'moment';
import { Button, Input } from 'semantic-ui-react';
import eventService from '../../../../services/eventService';
import Swal from 'sweetalert2';
import Barcode from 'react-barcode';
import ReactToPrint from 'react-to-print';
import { eventSlugnameTrim } from '../../../../components/helpers';
moment.lang('es', {
  months: 'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split(
    '_'
  ),
  monthsShort: 'Enero._Feb._Mar_Abr._May_Jun_Jul._Ago_Sept._Oct._Nov._Dec.'.split(
    '_'
  ),
  weekdays: 'Domingo_Lunes_Martes_Miércoles_Jueves_Viernes_Sábado'.split('_'),
  weekdaysShort: 'Dom._Lun._Mar._Mier._Jue._Vier._Sab.'.split('_'),
  weekdaysMin: 'Do_Lu_Ma_Mi_Ju_Vi_Sa'.split('_')
});

const ReservationSummary = props => {
  const { inputs, setInputs, setStep } = props;
  const [showform, set_showform] = React.useState(false);
  const [loading, set_loading] = React.useState(false);
  let printable = React.createRef();

  const cancelReservation = e => {
    e.preventDefault();
    const { lastName, identification, phone, age, eventSlugname } = inputs;

    if (!lastName) set_showform(true);

    Swal.fire({
      title: '¿Seguro que desea cancelar la reserva?',
      showCancelButton: true,
      confirmButtonText: 'Sí, cancelar',
      cancelButtonText: 'Volver'
    }).then(result => {
      if (result.value) {
        set_loading(true);
        eventService
        .cancelReservation({ cancelled: 'user', identification, phone, age, eventSlugname })
          .then(response => {
            if (response.data.success) {
              Swal.fire(response.data.message);
              setTimeout(() => {
                window.location.reload();
              }, 3000);
            } else {
              Swal.fire(response.data.message);
            }
          })
          .catch(error => {
            if (error.response) Swal.fire(error.response.data.message);
          });
      }
    });
  };
  const handleInputs = event => {
    let value = event.target.value;
    let name = event.target.name;
    setInputs({ ...inputs, [name]: value });
  };

  const printButton = () => (
    <ReactToPrint
      trigger={() => (
        <Button style={{ textDecoration: 'underline' }}>Imprimir</Button>
      )}
      content={() => printable.current}
    />
  );

  const reservationId = () => {
    if (inputs['AuditoriumGuests.id']) return inputs['AuditoriumGuests.id'];
    if (inputs.reserved['AuditoriumGuests.id'])
      return inputs.reserved['AuditoriumGuests.id'];
    if (inputs.reserved) return inputs.reserved.id;
    return 'N/A';
  };

  return (
    <div className="service summary">
      <div ref={printable} style={{ textAlign: 'center', padding: '50px' }}>
        <p className="title">
          ¡Bienvenid{inputs.gender === 'Femenino' ? 'a' : 'o'} {inputs.name}!
        </p>
        <QRCode
          value={`${process.env.REACT_APP_HOSTNAME}/checkin?event=Auditorio_Principal&eventSN=${inputs.eventSlugname}&id=${inputs.identification}`}
        />
        {/*inputs.selected && (
        <>
        <p className="seattitle">Asiento:</p>
        <p className="seatnumber">
        {inputs.selected.row}
        {inputs.selected.column}
        </p>
        </>
      )*/}
        <div className="barcode">
          <Barcode value={inputs.identification} />
        </div>
        <p>
          <strong>Código de reserva:</strong> {reservationId()}
        </p>

        <p>
          <strong>Reserva para {eventSlugnameTrim(inputs.eventSlugname)}</strong>
        </p>

        {inputs.allReservations &&
        (inputs.allReservations.length > 1 ||
          (inputs.allReservations.length === 1 &&
            inputs.allReservations[0]['AuditoriumGuests.volunteer'])) ? (
          <>
            {inputs.allReservations.map(a => (
              <>
                <p
                  style={{
                    margin: '5px 0 0 0',
                    fontSize: '15px'
                  }}
                >
                  {a['AuditoriumGuests.volunteer'] ? (
                    <strong>Entrada como voluntario</strong>
                  ) : (
                    <strong>Entrada como participante</strong>
                  )}
                </p>
                <p style={{ margin: 0 }}>
                  {a['AuditoriumGuests.id']}
                  {' - '}
                  {moment
                    .tz(a.date, 'America/Bogota')
                    .format('dddd DD [de] MMMM - hh:mm a')}
                </p>
              </>
            ))}
          </>
        ) : (
          <p>
            {moment
              .tz(inputs.date, 'America/Bogota')
              .format('dddd DD [de] MMMM - hh:mm a')}
          </p>
        )}
        <p style={{ textAlign: 'start', padding: '20px' }}>
          <small style={{ display: 'block' }}>
          Al llegar a ICCF debes presentar este código QR impreso o en tu celular. Es fácil: sólo toma un pantallazo y guárdalo entre tus fotos para llevarlo a la Iglesia.
          </small>
          <small style={{ display: 'block' }}>
          Si lo pierdes, puedes volver a obtenerlo ingresando a esta misma página. Al escribir tu cédula, teléfono y edad, la plataforma te mostrará de nuevo tu código de reserva.
          </small>
          <small style={{ display: 'block' }}>
Por favor, si no puedes asistir, cancela la reserva aquí mismo o en el whatsapp Comunife: 312-812-4885 para que otra persona pueda tomar el cupo.
          </small>
          <small style={{ display: 'block' }}>
Recuerda llegar con 30 minutos de anticipación.
          
          </small>
        </p>
      </div>
      <div className="actions" style={{ margin: '10px 0' }}>
        {showform && (
          <div>
            <p style={{ color: 'red' }}>Verifica estos datos para cancelar.</p>{' '}
            <Input
              placeholder="Celular"
              id="phone"
              name={'phone'}
              type="number"
              disabled={loading}
              className={`Registration__input Registration__input-${'phone'} ${''}`}
              value={inputs.phone}
              onChange={handleInputs}
              style={{ width: '33%', margin: '5px' }}
            />
            <Input
              style={{ width: '33%', margin: '5px' }}
              onChange={handleInputs}
              type="number"
              disabled={loading}
              name={'age'}
              value={inputs.age}
              placeholder="Edad"
              id="age"
              className={`Registration__input Registration__input-${'age'} ${''}`}
            />
          </div>
        )}
        <Button
          disabled={loading}
          secondary
          onClick={e => cancelReservation(e)}
        >
          Cancelar reserva
        </Button>
        {printButton()}
      </div>
    </div>
  );
};

export default ReservationSummary;
