import React from 'react';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import churchForms from '../../services/churchForms';
import VolunteersRow from '../../components/common/adminTable/volunteersRow';
import { Select, Input, Button } from 'semantic-ui-react';
import { churchMinistries } from '../../components/helpers';
import authService from '../../services/auth';
import ReportButton from '../admin/ReportButton';
import VolunteerRegisterFilled from '../admin/VolunteerRegFilled';

const AllVolunteers = props => {
  const [volunteers, set_volunteers] = React.useState([]);
  const [loaded, set_loaded] = React.useState(false);
  const [showVolunteer, set_showVolunteer] = React.useState(null);
  const [inputs, setInputs] = React.useState({});
  const currentUser = authService.getCurrentUser();

  React.useEffect(() => {
    getInfo();
  }, [inputs.serviceArea]);

  const onEnter = e => {
    if (e.key === 'Enter') {
      getInfo();
    }
  };

  const serviceArea = inputs.serviceArea || '';

  function getInfo() {
    set_loaded(false);

    let body = { keyword: inputs.keyword };

    churchForms
      .getVolunteerForm({ body, serviceArea })
      .then(response => {
        if (response.data.success) {
          set_loaded(true);
          set_volunteers(response.data.volunteers);
        }
      })
      .catch(error => {
        if (error.response) Swal.fire(error.response.data.message);
        set_loaded(false);
      });
  }

  const selectHandle = (a, { value, name }) => {
    setInputs({ ...inputs, [name]: value });
  };

  return (
    <section className="AdminTable">
      {!showVolunteer && (
        <>
          <div className="AdminTable__content">
            <div>
              <Button primary onClick={() => props.history.push('/')}>
                Atrás
              </Button>
              <Button primary onClick={() => getInfo()}>
                Recargar
              </Button>
              <ReportButton
                URL={`/experience/VolunteersReport${
                  serviceArea ? `/${serviceArea}` : ''
                }`}
                fileName={`reporte_voluntarios.xlsx`}
                event="Descargar Reporte"
              />
              <Button
                secondary
                onClick={() => props.history.push('/registro-voluntario')}
              >
                Inscribir
              </Button>
            </div>
            <Input
              loading={!loaded}
              name={'keyword'}
              onChange={e =>
                setInputs({ ...inputs, ['keyword']: e.target.value })
              }
              value={inputs.keyword}
              onKeyPress={onEnter}
              style={{ width: '45%' }}
              placeholder="Buscar..."
            />
            <Select
              placeholder="Selecciona el area para consultar."
              style={{
                display: 'inline-block',
                margin: ' 7px 0',
                width: '45%'
              }}
              onChange={selectHandle}
              name="serviceArea"
              loading={!loaded}
              options={[
                { key: '', value: '', text: 'Todos' },
                ...churchMinistries.map(a => {
                  return { key: a, value: a, text: a };
                })
              ]}
            />
          </div>
          <p>Total en pantalla: {volunteers.length}</p>
        </>
      )}
      {loaded ? (
        <div>
          <table>
            {!showVolunteer && (
              <thead>
                <tr>
                  <th>Cedula</th>
                  <th>Nombre</th>
                  <th>Apellido</th>
                  <th>Celular</th>
                  <th>Area de Servicio</th>
                  <th>Ver</th>
                  {currentUser.status.includes('totalAccess') && (
                    <th>Eliminar</th>
                  )}
                </tr>
              </thead>
            )}
            <tbody>
              {volunteers.map(Volunteer => (
                <>
                  {showVolunteer === Volunteer.id && (
                    <VolunteerRegisterFilled inputs={Volunteer} />
                  )}
                  {!showVolunteer && (
                    <VolunteersRow
                      Volunteer={Volunteer}
                      getInfo={getInfo}
                      currentUser={currentUser}
                      set_showVolunteer={set_showVolunteer}
                    />
                  )}
                </>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p>Cargando</p>
      )}
    </section>
  );
};

export default AllVolunteers;
