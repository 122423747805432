import proxybase from './proxybase';
import Swal from 'sweetalert2';
import authService from './auth';

class reportService {
  constructor() {
    this.token = localStorage.getItem('userToken');
    this.getReport = this.getReport.bind(this);
  }
  getReport({ URL, fileName, body = {} }) {
    return proxybase
      .post(URL, body, {
        responseType: 'arraybuffer',
        headers: {
          Authorization: this.token,
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        const type = response.headers['content-type'];
        if (!type.includes('json')) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
        } else {
          const resp = JSON.parse(
            String.fromCharCode.apply(null, new Uint8Array(response.data))
          );
          if (resp.message) Swal.fire(resp.message);
        }
        return Promise.resolve(response);
      })
      .catch((error) => {
        if (error.response.status === 401) authService.logout();
        return Promise.reject(error);
      });
  }
}
export default new reportService();
