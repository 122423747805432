import React from 'react';
import { Input, Button, Checkbox } from 'semantic-ui-react';
import moment from 'moment';
import ChurchServiceGuestsRow from '../../../components/common/adminTable/ChurchServiceGuestsRow';

moment.lang('es', {
  months: 'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split(
    '_'
  ),
  monthsShort: 'Enero._Feb._Mar_Abr._May_Jun_Jul._Ago_Sept._Oct._Nov._Dec.'.split(
    '_'
  ),
  weekdays: 'Domingo_Lunes_Martes_Miércoles_Jueves_Viernes_Sábado'.split('_'),
  weekdaysShort: 'Dom._Lun._Mar._Mier._Jue._Vier._Sab.'.split('_'),
  weekdaysMin: 'Do_Lu_Ma_Mi_Ju_Vi_Sa'.split('_')
});

const ChurchServiceGuests = props => {
  const [inputs, setInputs] = React.useState({});
  const [inScreen, set_inScreen] = React.useState(0);

  const handleInputs = event => {
    let value = event.target.value;
    let name = event.target.name;
    setInputs({ ...inputs, [name]: value });
  };

  const filterUsers = (word, attended, volunteer) => {
    let serviceFiltered = props.serviceAttendance;
    if (word)
      serviceFiltered = serviceFiltered.filter(
        att =>
          att.Guest.name.toLowerCase().includes(word.toLowerCase()) ||
          att.Guest.lastName.toLowerCase().includes(word.toLowerCase()) ||
          att.Guest.identification.includes(word) ||
          att.Guest.phone.includes(word)
      );
    if (attended) serviceFiltered = serviceFiltered.filter(att => att.attended);
    if (volunteer)
      serviceFiltered = serviceFiltered.filter(att => att.volunteer);

    if (serviceFiltered.length !== inScreen)
      set_inScreen(serviceFiltered.length);
    return serviceFiltered;
  };

  return (
    <section className="AdminTable" id="network">
      <div className="AdminTable__content">
        <Button onClick={() => props.set_serviceAttendance([])}>Atrás</Button>
        <h2>Usuarios del servicio</h2>
        {props.clickedEvent && props.clickedEvent.eventSlugname && (
          <h3 style={{ textAlign: 'center', margin: '0' }}>
            <small>Auditorio:</small> {props.clickedEvent.eventSlugname}
          </h3>
        )}
        {props.clickedEvent && props.clickedEvent.date && (
          <p style={{ padding: '20px 0 0 0', margin: 0, fontSize: '24px' }}>
            <strong>
              {moment
                .tz(props.clickedEvent.date, 'America/Bogota')
                .format('dddd DD [de] MMMM hh:mm a')}
            </strong>
          </p>
        )}
        <p style={{ padding: '20px 0 0 0', margin: 0 }}>
          <strong>Total usuarios:</strong> {props.serviceAttendance.length}
        </p>
        <p style={{ padding: '0 0 0 0', margin: 0 }}>
          <strong>Total usuarios asistentes:</strong>{' '}
          {
            props.serviceAttendance.filter(
              att => att.volunteer === null && att.attended
            ).length
          }
        </p>
        <p style={{ padding: '0 0 0 0', margin: 0 }}>
          <strong>Total usuarios en pantalla:</strong> {inScreen}
        </p>
        <div className="options" style={{ padding: '20px 0 0 0', margin: 0 }}>
          <div className="search-query">
            <p style={{ padding: '0 0 0 0' }}>Buscar</p>
            <Input
              name={'keyword'}
              onChange={handleInputs}
              value={inputs.keyword}
              placeholder="Buscar"
            />
          </div>
          <div style={{ display: 'block', width: '100%' }}>
            <Checkbox
              label="Filtrar por asistencia"
              name="attended"
              checked={inputs.attended}
              toggle
              onClick={() =>
                setInputs({ ...inputs, attended: !inputs.attended })
              }
            />
          </div>
          <div style={{ display: 'block', width: '100%' }}>
            <Checkbox
              label="Filtrar por voluntarios"
              name="volunteer"
              checked={inputs.volunteer}
              toggle
              onClick={() =>
                setInputs({ ...inputs, volunteer: !inputs.volunteer })
              }
            />
          </div>
        </div>
      </div>

      <table style={{ margin: '20px 0' }}>
        <thead>
          <tr>
            <th>Cedula</th>
            <th>Nombre</th>
            <th>Celular</th>
            <th>Asistió</th>
            <th>Voluntario</th>
            <th>Esta semana</th>
          </tr>
        </thead>
        <tbody>
          {filterUsers(inputs.keyword, inputs.attended, inputs.volunteer).map(
            guest => (
              <ChurchServiceGuestsRow guest={guest} history={props.history} />
            )
          )}
        </tbody>
      </table>
    </section>
  );
};

export default ChurchServiceGuests;
