import proxybase from './proxybase';
import authService from './auth';
class eventService {
  constructor() {
    this.token = localStorage.getItem('userToken');
    this.getDates = this.getDates.bind(this);
  }
  getDates(event,body = {}) {
    return proxybase
    .post(
      `/event/eventDates/${event ? event : ''}`,body,
        {
          headers: { Authorization: this.token }
        }
      )
      .then(response => {
        return Promise.resolve(response);
      })
      .catch(error => {
        if (error.response.status === 401) authService.logout();
        return Promise.reject(error);
      });
  }
  updateDates(body) {
    return proxybase
      .put(`/event/eventDates`, body, {
        headers: { Authorization: this.token }
      })
      .then(response => {
        return Promise.resolve(response);
      })
      .catch(error => {
        if (error.response.status === 401) authService.logout();
        return Promise.reject(error);
      });
  }
  getSiteMap(body) {
    return proxybase
      .post(`/event/seatmap`, body, {
        headers: { Authorization: this.token }
      })
      .then(response => {
        return Promise.resolve(response);
      })
      .catch(error => {
        if (error.response.status === 401) authService.logout();
        return Promise.reject(error);
      });
  }
  CheckAuditoriumInscription(body) {
    return proxybase
      .post(`/event/checkInscription`, body, {
        headers: { Authorization: this.token }
      })
      .then(response => {
        return Promise.resolve(response);
      })
      .catch(error => {
        if (error.response.status === 401) authService.logout();
        return Promise.reject(error);
      });
  }
  MakeAReservation(body) {
    return proxybase
      .post(`/event/MakeAReservation`, body, {
        headers: { Authorization: this.token }
      })
      .then(response => {
        return Promise.resolve(response);
      })
      .catch(error => {
        if (error.response.status === 401) authService.logout();
        return Promise.reject(error);
      });
  }
  getAuditoriumEvents(body) {
    return proxybase
      .post(`/event/getAuditoriumEvents`, body, {
        headers: { Authorization: this.token }
      })
      .then(response => {
        return Promise.resolve(response);
      })
      .catch(error => {
        if (error.response.status === 401) authService.logout();
        return Promise.reject(error);
      });
  }
  getEventsNames(body) {
    return proxybase
      .post(`/event/getEventsNames`, body, {
        headers: { Authorization: this.token }
      })
      .then(response => {
        return Promise.resolve(response);
      })
      .catch(error => {
        if (error.response.status === 401) authService.logout();
        return Promise.reject(error);
      });
  }
  cancelReservation(body) {
    return proxybase
      .post(`/event/CancelAReservation`, body, {
        headers: { Authorization: this.token }
      })
      .then(response => {
        return Promise.resolve(response);
      })
      .catch(error => {
        if (error.response.status === 401) authService.logout();
        return Promise.reject(error);
      });
  }
  markAttendance(body) {
    return proxybase
      .post(`/event/markAttendance`, body, {
        headers: { Authorization: this.token }
      })
      .then(response => {
        return Promise.resolve(response);
      })
      .catch(error => {
        if (error.response.status === 401) authService.logout();
        return Promise.reject(error);
      });
  }
  createEvent(body) {
    return proxybase
      .post(`/event/createEvent`, body, {
        headers: { Authorization: this.token }
      })
      .then(response => {
        return Promise.resolve(response);
      })
      .catch(error => {
        if (error.response.status === 401) authService.logout();
        return Promise.reject(error);
      });
  }
  deleteEvent(body) {
    return proxybase
      .post(`/event/deleteEvent`, body, {
        headers: { Authorization: this.token }
      })
      .then(response => {
        return Promise.resolve(response);
      })
      .catch(error => {
        if (error.response.status === 401) authService.logout();
        return Promise.reject(error);
      });
  }
  editEvent(body) {
    return proxybase
      .post(`/event/editEvent`, body, {
        headers: { Authorization: this.token }
      })
      .then(response => {
        return Promise.resolve(response);
      })
      .catch(error => {
        if (error.response.status === 401) authService.logout();
        return Promise.reject(error);
      });
  }
  eventAttendance(body) {
    return proxybase
      .post(`/event/eventAttendance`, body, {
        headers: { Authorization: this.token }
      })
      .then(response => {
        return Promise.resolve(response);
      })
      .catch(error => {
        if (error.response.status === 401) authService.logout();
        return Promise.reject(error);
      });
  }
}
export default new eventService();
