import React from 'react';
import 'react-dates/lib/css/_datepicker.css';
import { Button, Input, Checkbox, Form } from 'semantic-ui-react';
import { errorInput } from '../../../../components/helpers';
import ReservationStayHome from './stayHome';

const covidQuestions = [
  {
    variab: 'syntoms',
    question:
      '¿Tiene dolor de garganta?'
  },
  {
    variab: 'malestar',
    question: '¿Tiene sensación de fatiga o malestar general?	'
  },
  {
    variab: 'fever',
    question: '¿Tiene fiebre? (Más de 38°C )'
  },
  {
    variab: 'tos',
    question: '¿Tiene tos seca y persistente?'
  },
  {
    variab: 'respirar',
    question: '¿Tiene dificultad para respirar?'
  },
  {
    variab: 'nasal',
    question: '¿Tiene secreciones o congestión nasal?'
  },
  {
    variab: 'gusto',
    question: '¿Tiene pérdida del olfato o gusto?'
  },
  {
    variab: 'previusCovidContact',
    question:
      '¿Vive con alguien sospechoso o confirmado de tener COVID-19?'
  },
  {
    variab: 'medicalConditions',
    question:
      '¿En los últimos 14 días ha tenido contacto estrecho (por más de 15 minutos, a menos de 2 metros y sin uso de elementos de protección personal) con alguien sospechoso o confirmado de tener COVID-19?'
  }
];

const ReservationStepTeCuidaInfo = props => {
  const { inputs, setInputs, setStep } = props;
  const [errorInputs, set_errorInputs] = React.useState({});
  const [loading, set_loading] = React.useState(false);

  React.useEffect(() => {
    setInputs({
      ...inputs,
      syntoms: null,
      previusCovidContact: null,
      fever: null,
      medicalConditions: null
    });
  }, []);

  const verifyForm = () => {
    const mandatoryInputs = [
      'emergencyNumber',
      'emergencyContact',
      'syntoms',
      'previusCovidContact',
      'fever',
      'medicalConditions', 'gusto', 'nasal', 'respirar', 'tos', 'malestar'
    ];

    const boolmandatoryInputs = [
      'syntoms',
      'previusCovidContact',
      'fever',
      'medicalConditions', 'gusto', 'nasal', 'respirar', 'tos', 'malestar'
    ];

    let errorInputs = {};

    mandatoryInputs.forEach(input => {
      errorInputs = errorInput({
        errorInputs,
        input: { name: input, value: inputs[input] }
      });
    });

    boolmandatoryInputs.forEach(input => {
      errorInputs = errorInput({
        errorInputs,
        input: { name: input, value: typeof inputs[input] === 'boolean' }
      });
    });

    if (
      inputs['syntoms'] ||
      inputs['previusCovidContact'] ||
      inputs['fever'] ||
      inputs['medicalConditions'] ||
      inputs['gusto'] || inputs['nasal'] || inputs['respirar'] || inputs['tos'] || inputs['malestar']
    ) {
      errorInputs = errorInput({
        errorInputs,
        input: { name: 'covidUnable', value: false }
      });
    }

    set_errorInputs(errorInputs);
    return Object.values(errorInputs).includes(true);
  };

  const nextStep = e => {
    if (verifyForm()) return;
    setStep('selectAService');
  };
  const handleInputs = event => {
    let value = event.target.value;
    let name = event.target.name;
    setInputs({ ...inputs, [name]: value });
  };

  const handleCheckers = (name, bool) => setInputs({ ...inputs, [name]: bool });

  const checkbox = (variab, label, bool) => (
    <Checkbox
      radio
      label={label}
      name={label}
      checked={inputs[variab] === bool}
      className={`${errorInputs[variab] ? 'error' : ''}`}
      onChange={() => handleCheckers(variab, bool)}
    />
  );

  const boolQues = ({ variab, question }) => (
    <Form.Field>
      <span className="options">
        {checkbox(variab, 'Si', true)}
        {checkbox(variab, 'No', false)}
      </span>
      <label className={`${errorInputs[variab] ? 'error' : ''}`}>
        {question}
      </label>
    </Form.Field>
  );

  return (
    <div className="service quarantine teCuidaInfo">
      <h2>Información de tu salud</h2>
      <label style={{ display: 'block', padding: '20px', fontSize: '30px' }}>
        Responde este cuestionario
      </label>
      {errorInputs['covidUnable'] && <ReservationStayHome />}
      <Input
        style={{ width: '46%', margin: '5px' }}
        onChange={handleInputs}
        name={'emergencyContact'}
        value={inputs.emergencyContact}
        placeholder="Contacto de emergencia"
        id="emergencyContact"
        className={`Registration__input Registration__input-${'emergencyContact'} ${
          errorInputs['emergencyContact'] ? 'error' : ''
          }`}
      />
      <Input
        type="number"
        style={{ width: '46%', margin: '5px' }}
        onChange={handleInputs}
        name={'emergencyNumber'}
        value={inputs.emergencyNumber}
        type="number"
        placeholder="Numero de emergencia"
        id="emergencyNumber"
        className={`Registration__input Registration__input-${'emergencyNumber'} ${
          errorInputs['emergencyNumber'] ? 'error' : ''
          }`}
      />
      <div className="boolean-questions">
        <small>Responde cuidadosamente las siguientes preguntas.</small>
        {covidQuestions.map(q =>
          boolQues({ question: q.question, variab: q.variab })
        )}
        <p>Por su seguridad, recomendamos a los adultos mayores o personas con afecciones médicas preexistentes (como hipertensión, enfermedades cardíacas o diabetes) a quedarse en casa recibiendo la Palabra a través de nuestras transmisiones en vivo.</p>
        {Object.values(errorInputs).includes(true) && (
          <h4 style={{ color: 'red' }}>
            Por favor verifica los campos en rojo.
          </h4>
        )}
        <Button primary disabled={loading} onClick={e => nextStep(e)}>
          Continuar
        </Button>
      </div>
    </div>
  );
};

export default ReservationStepTeCuidaInfo;
