import React from 'react';
import ReservationStepCheck from './reservation/stepCheck';
import ReservationStepFillData from './reservation/stepFillData';
import ReservationStepTeCuidaInfo from './reservation/stepteCuidaInfo';
import SeatMap from './reservation/seatMap';
import ReservationStepSelectAService from './reservation/selectAService';
import ReservationSummary from './reservation/summary';
import queryString from 'query-string';

const ServiceReservation = props => {
  const [Step, setStep] = React.useState('check');
  const [inputs, setInputs] = React.useState({});

  React.useEffect(() => {
    if (document) {
      try {
        document.title = 'Comunidad Cali | Reserva tu lugar ';
        let query = props.location.search;
        let parsedQueryString = queryString.parse(query);
        let eventSlugname = 'Auditorio_Principal'
        if (parsedQueryString.event) {
          eventSlugname = parsedQueryString.event
        }
        setInputs({...inputs,eventSlugname})
      } catch (error) {
        console.log(error);
      }
    }
  }, []);

  const secAudAv = inputs.age < 14

  const stepRender = () => {
    if (Step === 'check') {
      return (
        <ReservationStepCheck
          setInputs={setInputs}
          inputs={inputs}
          setStep={setStep}
          secAudAv={secAudAv}
        />
      );
    } else if (Step === 'FillData') {
      return (
        <ReservationStepFillData
          setInputs={setInputs}
          setStep={setStep}
          secAudAv={secAudAv}
          inputs={inputs}
        />
      );
    } else if (Step === 'teCuidaInfo') {
      return (
        <ReservationStepTeCuidaInfo
          setInputs={setInputs}
          setStep={setStep}
          inputs={inputs}
          secAudAv={secAudAv}
        />
      );
    } else if (Step === 'selectAService') {
      return (
        <ReservationStepSelectAService
          setInputs={setInputs}
          secAudAv={secAudAv}
          setStep={setStep}
          inputs={inputs}
        />
      );
    } else if (Step === 'seatmap') {
      return (
        <SeatMap setInputs={setInputs} setStep={setStep} inputs={inputs} />
      );
    } else if (Step === 'summary') {
      return (
        <ReservationSummary
          setInputs={setInputs}
          setStep={setStep}
          inputs={inputs}
        />
      );
    }
  };

  return <div>{stepRender()}</div>;
};

export default ServiceReservation;
