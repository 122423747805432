import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Layout from '../components/layout/Layout';
import Home from '../containers/Home';
import Registration from '../containers/Registration';
import Error404 from '../containers/Error404';
import CheckIn from '../containers/CheckIn/CheckIn';
import PrintQueueTable from '../containers/PrintTable';
import Login from '../containers/Login';
import VolunteerReg from '../containers/VolunteerReg';
import '../assets/styles/App.scss';
import PrintPage from '../components/printQueue/printPage';
import authService from '../services/auth';
import AllGuests from '../containers/admin/all-guests';
import ServiceReservation from '../containers/church-members/church-service/reservation';
import SeatMap from '../containers/church-members/church-service/reservation/seatMap';
import ViewAllServices from '../containers/admin/church-service/listServices';
import AllVolunteers from '../containers/volunteer/AllVolunteers';

const App = () => {
  const loc = window.location;
  const protocol = process.env.REACT_APP_PROTOCOL || 'https'
  const hostname = process.env.REACT_APP_HOSTNAME || 'localhost'

  React.useEffect(() => {
    if (loc.hostname === hostname) {
      if (loc.protocol !== `${protocol}:`) {
        loc.replace(`${protocol}:${loc.href.substring(loc.protocol.length)}`);
      }
    }
    if (loc.hostname !== hostname) {
      loc.replace(`${protocol}://${hostname}${loc.pathname}${loc.search}`);
    }
  }, []);
  
  function Logout({ component: Component, ...rest }) {
    if (localStorage.getItem('userToken')) {
      return authService.logout();
    }
  }
  function PrivateRoute({ component: Component, ...rest }) {
    if (localStorage.getItem('userToken')) {
      return (
        <Route
          {...rest}
          render={props => (
            <Layout>
              <Component {...props} />
            </Layout>
          )}
        />
      );
    } else {
      return window.location.replace('/');
    }
  }
  function RouteLayout({ component: Component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props => (
          <Layout>
            <Component {...props} />
          </Layout>
        )}
      />
    );
  }
  if (
    (loc.hostname === hostname && loc.protocol === `${protocol}:`)
  ) {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/checkin/print" component={PrintPage} />
          <Route exact path="/logout" component={Logout} />
          <RouteLayout exact path="/" component={Home} />
          <RouteLayout exact path="/login" component={Login} />
          <RouteLayout exact path="/seatmap" component={SeatMap} />

          <RouteLayout
            exact
            path="/registro-voluntario"
            component={VolunteerReg}
          />
          <RouteLayout
            exact
            path="/servicio/reservacion"
            component={ServiceReservation}
          />
          <PrivateRoute
            exact
            path="/checkin/print-queue"
            component={PrintQueueTable}
          />
          <PrivateRoute
            exact
            path="/all-volunteers"
            component={AllVolunteers}
          />
          <PrivateRoute exact path="/checkin" component={CheckIn} />

          <PrivateRoute exact path="/registration" component={Registration} />

          <PrivateRoute exact path="/all-guests" component={AllGuests} />
          <PrivateRoute
            exact
            path="/church-services"
            component={ViewAllServices}
          />
          <RouteLayout component={Error404} />
        </Switch>
      </BrowserRouter>
    );
  } else {
    return <p>Cargando una conexión segura</p>;
  }
};

export default App;
