import React from 'react';
import logoBlue from '../assets/static/logo-blue.png';
import { churchMinistries, errorInput } from '../components/helpers';
import { Button, Select } from 'semantic-ui-react';
import churchForms from '../services/churchForms';
import Swal from 'sweetalert2';
import VolunteerForm from './volunteer/VolunteerForm';
import ReCAPTCHA from 'react-google-recaptcha';

const VolunteerRegister = props => {
  const [inputs, setInputs] = React.useState({});
  const [errorInputs, set_errorInputs] = React.useState({});
  const [sending, set_sending] = React.useState(false);
  const [loadedData, set_loadedData] = React.useState(false);

  //let canvasSign = {};

  const form_verification = () => {
    let mandatoryInputs = [
      'name',
      'lastName',
      'identification',
      'cellPhone',
      'email',
      'address',
      'serviceArea'
    ];

    if (window.location.hostname !== 'localhost') {
      mandatoryInputs = [...mandatoryInputs, 'captcha'];
    }

    let errorInputs = {};

    mandatoryInputs.forEach(input => {
      errorInputs = errorInput({
        errorInputs,
        input: { name: input, value: inputs[input] }
      });
    });

    if (window.location.hostname !== 'localhost') {
      if (inputs['captcha'] && inputs['captcha'] != 'null') {
        errorInputs = errorInput({
          errorInputs,
          input: {
            name: 'captcha',
            value: inputs['captcha'].length >= 10
          }
        });
      }
    }

    set_errorInputs(errorInputs);
    return Object.values(errorInputs).includes(true);
  };

  function captchaOnChange(value) {
    setInputs({ ...inputs, captcha: value.split(':').pop() });
  }

  function sendForm(e) {
    if (form_verification()) return;
    set_sending(true);

    set_loadedData(true);
    churchForms
      .volunteerForm({ ...inputs })
      .then(response => {
        if (response.data.success) {
          Swal.fire('Enviado correctamente');
          setInputs({});
        }
        set_sending(false);
      })
      .catch(error => {
        set_sending(false);
        if (error) {
          console.log(error);
          Swal.fire(
            'Hay un error en el formulario, por favor revisa y vuelve a intentar.'
          );
        }
        set_loadedData(false);
      });
  }

  const handleInputs = event => {
    let value = event.target.value;
    let name = event.target.name;
    setInputs({ ...inputs, [name]: value });
  };

  const selectHandle = (a, { value, name }) => {
    setInputs({ ...inputs, [name]: value });
  };

  const input = ({ input, type = 'input', placeholder = '' }) => {
    return (
      <input
        type={type}
        name={input}
        placeholder={placeholder}
        className={`inline-form ${errorInputs[input] ? 'error' : ''}`}
        value={inputs[input] || ''}
        onChange={handleInputs}
      />
    );
  };

  const selectkindofid = () => {
    return (
      <select
        id="kind"
        onChange={e =>
          handleInputs({
            target: { value: e.target.value, name: 'kindofIdentification' }
          })
        }
        value={inputs.kindofIdentification}
      >
        <option value="C.C">C.C</option>
        <option value="C.E">C.E</option>
        <option value="T.I">T.I</option>
        <option value="PP">PP</option>
        <option value="PEP">PEP</option>
      </select>
    );
  };

  const selectArea = () => (
    <Select
      placeholder="Selecciona el area en la que sirves."
      style={{
        display: 'block',
        margin: ' 7px 0'
      }}
      className={errorInputs['serviceArea'] ? 'error' : ''}
      onChange={selectHandle}
      name="serviceArea"
      options={churchMinistries.map(a => {
        return { key: a, value: a, text: a };
      })}
    />
  );

  //const today = new Date();
  return (
    <div className="volunteer-registration">
      <div className="inner">
        <header>
          <img src={logoBlue} />
          <h2>DATOS PERSONALES DEL VOLUNTARIO</h2>
        </header>

        <VolunteerForm
          input={input}
          selectkindofid={selectkindofid}
          errorInputs={errorInputs}
          selectHandle={selectHandle}
          selectArea={selectArea}
        />
        <div className="signs-form">
          {/*<p>
            Para constancia se firma a los: <b>{today.getDate()}</b> del mes de{' '}
            <b>{today.toLocaleDateString('es-ES', { month: 'long' })}</b> de{' '}
            <b>{today.getFullYear()}</b>
          </p>*/}
          <div className="volunteerSign">
            {/* <> <Button
              style={{ display: 'block', margin: ' 7px 0' }}
              size="mini"
              onClick={() => canvasSign.clear()}
            >
              Limpiar firma
            </Button>
            <SignatureCanvas
              penColor="black"
              ref={ref => {
                canvasSign = ref;
              }}
              canvasProps={{
                width: 300,
                height: 200,
                className: `sigCanvas  ${errorInputs['sign'] ? 'error' : ''}`
              }}
            />
            <p>Firma del Voluntario</p>
            <p>
              <strong>{inputs.name}</strong> <strong>{inputs.lastName}</strong>
            </p>
            <p>{inputs.identification}</p></> */}
            <div className="actions">
              <div className={`captcha ${errorInputs.captcha ? 'error' : ''}`}>
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RECAPTCHA}
                  onChange={captchaOnChange}
                />
              </div>
              <p style={{ color: 'red' }}>
                {errorInputs.captcha ? 'Por favor resuelve el captcha.' : ''}
              </p>
              {Object.values(errorInputs).includes(true) && (
                <h4 style={{ color: 'red' }}>
                  Por favor verifica los campos obligatorios en rojo.
                </h4>
              )}
              <p style={{ fontStyle: 'normal' }}>
                Amado voluntario, para ICCF es fundamental contar con tu
                autorización expresa para servir como voluntario a la Iglesia
                mediante el
                <a
                  target="_blank"
                  href="https://comunifecali.org/wp-content/uploads/2020/12/servidores-Convenio-ministerial-y-de-voluntariado-ICCF.-V.-2020.pdf"
                  style={{ textDecoration: 'underline' }}
                >
                  {' '}
                  CONVENIO DE VOLUNTARIOS en este enlace
                </a>
              </p>
              <p>
                Al enviar aceptas el tratamiento de datos de Comunidad Cristiana
                de Fe
              </p>
              <Button onClick={() => sendForm()} primary disabled={sending}>
                Enviar
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default VolunteerRegister;
