import React from 'react';
import { Swal } from 'sweetalert2/dist/sweetalert2';
import Form from '../components/form/Form';
import guestService from '../services/guest';

const Registration = props => {
  const [Attendant, set_Attendant] = React.useState({});
  const [loadedData, set_loadedData] = React.useState(false);
  const [errorData, set_errorData] = React.useState(null);

  const handleInputs = event => {
    let value = event.target.value;
    let name = event.target.name;
    set_Attendant({ ...Attendant, [name]: value });
  };

  const handleDropDown = ({ name, value }) => {
    set_Attendant({ ...Attendant, [name]: value });
  };

  React.useEffect(() => {
    setTimeout(() => {
      set_errorData(null);
    }, 6000);
  }, [errorData]);

  function createAttendant(e) {
    e.preventDefault();

    const { name, lastName, phone, identification, email } = Attendant;

    if (!name || !lastName || !phone || !identification) {
      set_errorData('Todos los campos son obligatorios');
      return;
    }
    set_loadedData(true);
    guestService
      .createGuest({
        name,
        lastName,
        phone,
        identification,
        email
      })
      .then(response => {
        if (response.data.success) {
          Swal.fire(response.data.message);
        }
      })
      .catch(error => {
        set_errorData(error.response.data.message);
        set_loadedData(false);
      });
  }

  return (
    <section className="Registration">
      <h1 className="Registration__title">Registro</h1>
      <form className="Registration__form">
        <Form
          saveTag={'Crear participante '}
          Attendant={Attendant}
          userprofile={true}
          handleInputs={handleInputs}
          handleDropDown={handleDropDown}
          errorInputs={{}}
          sendInForm
          loadedData={loadedData}
          submit={createAttendant}
        />
      </form>
      <p>{errorData}</p>
    </section>
  );
};
export default Registration;
