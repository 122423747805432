import React from 'react';
import { Button } from 'semantic-ui-react';
import moment from 'moment';
import eventService from '../../../../services/eventService';
import Swal from 'sweetalert2';
import { addHours, eventSlugnameTrim } from '../../../../components/helpers';

const EntryCheckpoint = props => {
  const { Attendant, set_errorData, reserved } = props;
  const [loading, set_loading] = React.useState(false);

  const inHours = date =>
    addHours(-1, date) < new Date() && new Date() < addHours(1, date);

  const ifVolunteerTakeLastEvent = () => {
    const resLength = Attendant.allReservations.length;
    if (resLength > 0) {
      for (let i = 0; i < resLength; i++) {
        if (
          Attendant.allReservations[i]['AuditoriumGuests.attended'] ===
          null
        ) {
          return Attendant.allReservations[i];
        }
      }
    }
    return Attendant.reserved;
  };

  React.useEffect(() => {
    if (
      ifVolunteerTakeLastEvent() &&
      ifVolunteerTakeLastEvent().date &&
      !inHours(ifVolunteerTakeLastEvent().date)
    )
      Swal.fire('Entrada fuera de horas');
  }, []);

  function markEntry(event) {
    Swal.fire({
      title: '¿Marcar la entrada?',
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'No, Volver'
    }).then(result => {
      if (result.value) {
        set_loading(true);

        let body = {
          audEvent_id: event['AuditoriumGuests.audEvent_id'],
          audGuest_id: event['AuditoriumGuests.id'],
          guest_id: event['AuditoriumGuests.guest_id']
        };

        if (ifVolunteerTakeLastEvent()['AuditoriumGuests.volunteer'])
          body = { ...body, allDay: true };

        eventService
          .markAttendance(body)
          .then(response => {
            set_loading(false);
            if (response.data.success) {
              set_errorData(response.data.message);
              window.location.replace(`/checkin?event=Auditorio_Principal&eventSN=${Attendant.reserved['eventSlugname']}`);
            } else {
              Swal.fire(response.data.message);
            }
          })
          .catch(error => {
            set_loading(false);
            if (error.response) {
              set_errorData(error.response.data.message);
              Swal.fire(error.response.data.message);
            }
          });
      }
    });
  }

  const cancelReservation = ({ identification, phone, age, name, eventSlugname }) => {
    Swal.fire({
      title: `¿Seguro que desea cancelar la reserva de ${name}?`,
      showCancelButton: true,
      confirmButtonText: 'Si, cancelar',
      cancelButtonText: 'Volver'
    }).then(result => {
      if (result.value) {
        eventService
          .cancelReservation({ cancelled: 'admin', identification, phone, age, eventSlugname })
          .then(response => {
            if (response.data.success) {
              Swal.fire(response.data.message);
              setTimeout(() => {
                window.location.reload();
              }, 3000);
            } else {
              Swal.fire(response.data.message);
            }
          })
          .catch(err => {
            if (err.response && err.response.data) Swal.fire(err.response.data.message);
          });
      }
    });
  };

  return (
    <>
      {Attendant.allReservations &&
      (Attendant.allReservations.length > 1 ||
        (Attendant.allReservations.length === 1 &&
          Attendant.allReservations[0]['AuditoriumGuests.volunteer'])) ? (
        <>
          {Attendant.allReservations.map(a => (
            <>
              <p
                style={{
                  margin: '5px 0 0 0',
                  fontSize: '15px'
                }}
              >
                {a['AuditoriumGuests.volunteer'] ? (
                  <strong>
                    Entrada como voluntario - {a['AuditoriumGuests.volunteer']}
                  </strong>
                ) : (
                  <strong>Entrada como participante</strong>
                )}
              </p>
              <p style={{ margin: 0 }}>
                <span
                  style={a['AuditoriumGuests.attended'] ? { color: 'red' } : {}}
                >
                  {a['AuditoriumGuests.id']}
                </span>
                {' - '}
                {moment
                  .tz(a.date, 'America/Bogota')
                  .format('dddd DD [de] MMMM - hh:mm a')}
              </p>
            </>
          ))}
        </>
      ) : (
        <>
          <p style={{ margin: 0 }}>
            {moment
              .tz(ifVolunteerTakeLastEvent().date, 'America/Bogota')
              .format('dddd DD [de] MMMM - hh:mm a')}
          </p>
          {!inHours(ifVolunteerTakeLastEvent().date) && (
            <p style={{ color: 'red', margin: 0 }}>
              Entrada fuera de horas.
            </p>
          )}
        </>
      )}
      {Attendant && Attendant.reserved &&
      <>
        {Attendant.reserved['eventSlugname'] && (
          <p style={{fontWeight:"bold"}}>
            <small>Auditorio:</small> {eventSlugnameTrim(Attendant.reserved['eventSlugname']) }
          </p>
        )}

        {Attendant.reserved['AuditoriumGuests.parentFullname'] && Attendant.reserved['AuditoriumGuests.parentIdentification'] && (
          <p style={{fontWeight:"bold"}}>
            <span style={{display: 'block'}}><small>Nombre del Acudiente:</small> {Attendant.reserved['AuditoriumGuests.parentFullname'] }</span>
            <small>Cedula del Acudiente:</small> {Attendant.reserved['AuditoriumGuests.parentIdentification']}
          </p>
        )}
      </>
      }
      <p style={{ margin: '10px 0 0 0' }}>
        <strong>Codigo de reserva:</strong>{' '}
        {ifVolunteerTakeLastEvent()['AuditoriumGuests.id']}
      </p>

      {Attendant.seat && (
        <p className="seatnumber">
          <strong>Asiento:</strong> {Attendant.seat.row}
          {Attendant.seat.column}
        </p>
      )}

      {ifVolunteerTakeLastEvent() &&
      ifVolunteerTakeLastEvent()['AuditoriumGuests.attended'] ? (
        <>
          <p style={{ color: 'red', marginBottom: 0 }}>
            Ya marca entrada para este fin de semana
          </p>
          <p style={{ margin: 0 }}>
            <small>Si crees que es un error comunicalo con soporte.</small>
          </p>
        </>
      ) : (
        <>
          <Button
            primary
            onClick={() => markEntry(ifVolunteerTakeLastEvent())}
            loading={loading}
          >
            Marcar Entrada
          </Button>
          <Button
            color="red"
            loading={loading}
            style={{ margin: '10px' }}
            onClick={e => cancelReservation({ ...Attendant, ...reserved })}
          >
            Cancelar Entrada
          </Button>
        </>
      )}
    </>
  );
};

export default EntryCheckpoint;
