import React from 'react';
import { Button } from 'semantic-ui-react';
import GuestModal from './guestModal';

const GuestRow = props => {
  const [show_modal, set_show_modal] = React.useState(false);
  const { guest, getInfo } = props;

  const { identification, name, phone, lastName, ocupation, email } = guest;

  return (
    <>
      {show_modal && (
        <GuestModal
          getInfo={getInfo}
          guest={guest}
          set_show_modal={set_show_modal}
        />
      )}
      <tr>
        <td>
          <p style={{ margin: '0' }}>{identification}</p>
        </td>
        <td>
          <p style={{ margin: '0' }}>
            {name} {lastName}
          </p>
        </td>
        <td>
          <p style={{ margin: '0' }}>{phone}</p>
        </td>
        <td>
          <p style={{ margin: '0' }}>{email}</p>
        </td>
        <td>
          <Button secundary onClick={() => set_show_modal(true)}>
            Ver
          </Button>
        </td>
      </tr>
    </>
  );
};

export default GuestRow;
