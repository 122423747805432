import React from 'react';
import { Button, Checkbox } from 'semantic-ui-react';
import eventService from '../../../../services/eventService';
import moment from 'moment';
import Swal from 'sweetalert2';
import { eventSlugnameTrim } from '../../../../components/helpers';
moment.lang('es', {
  months: 'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split(
    '_'
  ),
  monthsShort: 'Enero._Feb._Mar_Abr._May_Jun_Jul._Ago_Sept._Oct._Nov._Dec.'.split(
    '_'
  ),
  weekdays: 'Domingo_Lunes_Martes_Miércoles_Jueves_Viernes_Sábado'.split('_'),
  weekdaysShort: 'Dom._Lun._Mar._Mier._Jue._Vier._Sab.'.split('_'),
  weekdaysMin: 'Do_Lu_Ma_Mi_Ju_Vi_Sa'.split('_')
});

const ReservationStepSelectAService = props => {
  const { inputs, setInputs, setStep, secAudAv } = props;
  const [loading, set_loading] = React.useState(false);
  const [services, set_services] = React.useState({});

  const nextStep = service => {
    setInputs({ ...inputs, service, date: service.date });
    if (inputs.service && !inputs.reserved) sendForm();
  };

  React.useEffect(() => {
    //if (inputs.service) setStep('seatmap');
  }, [inputs]);

  const getDate = date =>
    moment.tz(date, 'America/Bogota').format('YYYY-MM-DD');

  React.useEffect(() => {
    set_loading(true);

    let eventSlugname = inputs.eventSlugname || 'Auditorio_Principal';
    if (secAudAv && !inputs.eventSlugname) eventSlugname = 'Kids';
    setInputs({ ...inputs, eventSlugname });

    let body = { eventSlugname, currentWeek: true };
    eventService
      .getAuditoriumEvents(body)
      .then(response => {
        if (response.data.success) {
          set_loading(false);
          let events = {};
          response.data.events.forEach(event => {
            events[getDate(event.date)] = [
              ...(events[getDate(event.date)] || []),
              event
            ];
          });

          set_services(events);
        }
      })
      .catch(err => {
        set_loading(false);
      });
  }, [inputs.eventSlugname]);

  const changeKindService = () => {
    if (inputs.eventSlugname === 'Kids')
      setInputs({ ...inputs, eventSlugname: 'Auditorio_Principal' });
    else setInputs({ ...inputs, eventSlugname: 'Kids' });
  };

  const sendForm = () => {
    set_loading(true);
    eventService
      .MakeAReservation({
        ...inputs,
        service: {
          ...(inputs.service || {}),
          eventSlugname: inputs.eventSlugname
        }
      })
      .then(response => {
        let resp = {};
        set_loading(false);
        if (response.data.success) {
          if (response.data.created) {
            if (response.data.reserved) {
              resp = { ...resp, reserved: response.data.reserved };
              if (response.data.allReservations)
                resp = {
                  ...resp,
                  allReservations: response.data.allReservations
                };
            }
            setInputs({ ...inputs, ...resp });
            Swal.fire({
              icon: 'info',
              title: 'Confirmación de la reserva',
              html:
                '<p>Esta reserva es personal e intransferible. </br> Tu familia e invitados también deben hacer la reserva de su respectivo cupo. </br> <strong>IMPORTANTE:</strong> Si antes del Servicio de Celebración presentas síntomas de Covid-19 o te relacionas con personas diagnosticadas de coronavirus debes cancelar tu reserva por tu salud y la de todos. Al quedarte en casa continúa recibiendo la Palabra a través de nuestras transmisiones en vivo.</p>',
              footer:
                '<a target="_blank" href="https://comunifecali.online.church/">https://comunifecali.online.church/</a>'
            });

            setStep('summary');
          }
        } else {
          Swal.fire(response.data.message);
        }
      })
      .catch(err => {
        set_loading(false);
      });
  };

  const includesServiceId = serviceId =>
    inputs.volunteerServices
      ? inputs.volunteerServices.includes(serviceId)
      : false;

  const addvolunteerService = serviceId => {
    let volunteerServices = inputs.volunteerServices;
    let service = inputs.service;

    if (includesServiceId(serviceId)) {
      volunteerServices = inputs.volunteerServices.filter(v => v !== serviceId);
    } else {
      volunteerServices = [
        ...new Set([...(inputs.volunteerServices || []), serviceId])
      ];
      if (serviceId === (inputs.service && inputs.service.id))
        service = { ...service, id: null };
    }
    setInputs({ ...inputs, volunteerServices, service });
  };

  const volunteerAttendtoService = serviceId => {
    let volunteerServices = inputs.volunteerServices;
    let service = inputs.service;

    if (includesServiceId(serviceId))
      volunteerServices = inputs.volunteerServices.filter(v => v !== serviceId);

    setInputs({
      ...inputs,
      volunteerServices,
      service: { ...service, id: serviceId }
    });
  };

  return (
    <div className="service selectAService">
      <h2>Ya casi terminamos {inputs.name}</h2>
      <label style={{ display: 'block', padding: '20px', fontSize: '35px' }}>
        {inputs.volunteer
          ? 'Por último, selecciona las reuniones en las que vas a servir'
          : 'Por último, selecciona la reunión a la que deseas asistir'}
      </label>
      {Boolean(inputs.serviceArea) && inputs.eventSlugname === 'Auditorio_Principal' && inputs.age > 13 && (
        <div className="checks">
          <Checkbox
            label={`¿Estas programado para servir este fin de semana? - ${
              inputs.volunteer ? 'Si' : 'No'
            }`}
            name="volunteer"
            checked={inputs.volunteer}
            toggle
            onClick={() =>
              setInputs({ ...inputs, volunteer: !inputs.volunteer })
            }
          />
        </div>
      )}
        <p style={{ textAlign: 'center', fontWeight: 'bold', margin: 0 }}>
          Reservando para {eventSlugnameTrim(inputs.eventSlugname)}
        </p>
      {Boolean(secAudAv) && (
        <div style={{ textAlign: 'center' }}>
          <Button
            loading={loading}
            disabled={loading}
            onClick={() => changeKindService()}
            style={{ margin: '20px 0' }}
          >
            Cambiar a{' '}
            {inputs.eventSlugname === 'Kids' ? 'Auditorio Principal' : 'Kids'}
          </Button>
        </div>
      )}
      {loading && <p>Cargando tu servicio...</p>}
      {!loading && Object.keys(services).length === 0 && (
        <p style={{ textAlign: 'center' }}>
          No hay reuniones disponibles, por favor consulta mas tarde.
        </p>
      )}
      {Boolean(inputs.volunteer) && (
        <p style={{ margin: '20px 0 0 0', maxWidth: '60%' }}>
          <strong>Importante:</strong> Recuerda que esta opción SOLO es valida
          si vas a servir como voluntario este fin de semana, si SOLO vas a
          asistir a una reunión desmarca esta casilla y selecciona uno de los
          servicios.
        </p>
      )}
      <div className="dates">
        {Object.keys(services).map(date => (
          <>
            <p style={{ padding: '20px 0 0 0' }}>
              {moment.tz(date, 'America/Bogota').format('dddd DD [de] MMMM')}
            </p>
            {services[date].map(service =>
              inputs.volunteer ? (
                <div>
                  <Checkbox
                    label={moment
                      .tz(service.date, 'America/Bogota')
                      .format('hh:mm a')}
                    name="volunteer"
                    checked={includesServiceId(service.id)}
                    toggle
                    onClick={() => addvolunteerService(service.id)}
                  />
                  <Checkbox
                    label="Asistir"
                    name="volunteer"
                    checked={
                      service.id === (inputs.service && inputs.service.id)
                    }
                    style={{ padding: '0 10px' }}
                    onClick={() => volunteerAttendtoService(service.id)}
                  />
                </div>
              ) : (
                <Button
                  primary
                  disabled={loading}
                  onClick={e => nextStep(service)}
                >
                  <div className="time">
                    {moment
                      .tz(service.date, 'America/Bogota')
                      .format('hh:mm a')}
                  </div>
                  {Boolean(service.capacity) && Boolean(service.total) && (
                    <div className="left">
                      Quedan{' '}
                      {parseInt(service.capacity) - parseInt(service.total)}{' '}
                      cupos
                    </div>
                  )}
                </Button>
              )
            )}
          </>
        ))}
      </div>
      {inputs.volunteer && (
        <Button
          disabled={
            !inputs.volunteerServices ||
            inputs.volunteerServices.length === 0 ||
            loading
          }
          loading={loading}
          primary
          onClick={() => sendForm()}
          style={{ margin: '20px 0' }}
        >
          Finalizar registro
        </Button>
      )}
    </div>
  );
};

export default ReservationStepSelectAService;
