import axios from 'axios';

let env =
  window.location.hostname === 'localhost' ? 'development' : 'production';
let baseURL;
if (env === 'development') {
  baseURL = 'http://localhost:5000/';
  console.log('DEVELOPMENT ENVIRONMENT', baseURL);
} else {
  baseURL = process.env.REACT_APP_NODE_ENV;
}
export default axios.create({
  baseURL,
});
