import React from 'react';
import { Button } from 'semantic-ui-react';

const PublicPage = props => {
  return (
    <>
      <section className="Home">
        <div className="Home__content">
          <h1 className="Home__title">Bienvenido</h1>
          <h2> Reserva tu cupo</h2>
          <p className="Home__intro">
            <strong
              style={{ display: 'block', padding: '20px 0 0 0' }}
            ></strong>
            <Button
              onClick={() => props.history.push('/servicio/reservacion')}
              disabled={false}
              secondary
            >
              ¡Reserva ahora!
            </Button>
          </p>
        </div>
      </section>
    </>
  );
};

export default PublicPage;
