import React from 'react';
import { Input, Select, Button } from 'semantic-ui-react';
import { formSelectService } from '../../components/helpers';
import eventService from '../../services/eventService';

const GuestIdForm = props => {
  const {
    guestID,
    set_guestID,
    getAttendant,
    loadedData,
    errorData,
    text,
    parsedQueryString
  } = props;
  const [eventsNames, set_eventsNames] = React.useState([]);

  React.useEffect(()=>{
    eventService
    .getEventsNames()
    .then(response => {
      if (response.data.success) {
        set_eventsNames(response.data.eventsNames)
      }
    })
    .catch(error => {})
  },[])
  
  const selectHandle = (a, { value, name }) => {
    props.history.push(`/checkin?event=${parsedQueryString.event}&eventSN=${value}`)
  };

  return (
    <section className="GuestIdForm">
      {text && (
        <h4
          dangerouslySetInnerHTML={{ __html: text }}
          style={{ textAlign: 'center' }}
        />
      )}
      <form onSubmit={e => getAttendant(e)} className="">
        {parsedQueryString.event === "Auditorio_Principal" && <div>
          {formSelectService({
            Select,
            label: 'Auditorio',
            name: 'eventSlugname',
            handler:selectHandle,
            value: parsedQueryString.eventSN,
            style:{width:"100%"},
            options: eventsNames
          })}
        </div>}
        <Input
          autoFocus
          placeholder="Identificación"
          type="number"
          style={{width:"100%"}}
          min={0}
          className="Registration__input Registration__input-id"
          value={guestID}
          onChange={e => set_guestID(e.target.value)}
        />
        {errorData && (
          <p style={{ color: 'red', textAlign: 'center' }}>{errorData}</p>
        )}
        <Button
          onClick={e => getAttendant(e)}
          style={{width:"100%"}}
          type="submit"
          disabled={loadedData}
        >Ver</Button>
      </form>
    </section>
  );
};

export default GuestIdForm;
