import React from 'react';
import 'react-dates/lib/css/_datepicker.css';
import { Button, Input, Select} from 'semantic-ui-react';
import ReCAPTCHA from 'react-google-recaptcha';
import eventService from '../../../../services/eventService';
import { errorInput, eventSlugnameTrim, formSelectService, clickableStyles } from '../../../../components/helpers';
import queryString from 'query-string';
import ServicesAvailabilityModal from '../helpers/ServicesAvailabilityModal'

const ReservationStepCheck = props => {
  const { inputs, setInputs, setStep } = props;
  const [errorInputs, set_errorInputs] = React.useState({});
  const [showAvailabilityModal, set_showAvailabilityModal] = React.useState(false);
  const [loading, set_loading] = React.useState(false);
  const [eventsNames, set_eventsNames] = React.useState([]);
  
  let query = window.location.search;
  let parsedQueryString = queryString.parse(query);

  const verifyForm = () => {
    let mandatoryInputs = ['identification', 'age', 'phone'];

    if (window.location.hostname !== 'localhost') {
      mandatoryInputs = [...mandatoryInputs, 'captcha'];
    }

    let errorInputs = {};

    mandatoryInputs.forEach(input => {
      errorInputs = errorInput({
        errorInputs,
        input: { name: input, value: inputs[input] }
      });
    });

    if (window.location.hostname !== 'localhost') {
      if (inputs['captcha'] && inputs['captcha'] != 'null') {
        errorInputs = errorInput({
          errorInputs,
          input: {
            name: 'captcha',
            value: inputs['captcha'].length >= 10
          }
        });
      }
    }

    if (inputs['identification'] && inputs['identification'].length <= 5) {
      errorInputs = errorInput({
        errorInputs,
        input: {
          name: 'identification',
          value: inputs['identification'].length >= 5
        }
      });
    }

    if (inputs['phone'] && inputs['phone'].length <= 10) {
      errorInputs = errorInput({
        errorInputs,
        input: {
          name: 'phone',
          value: inputs['phone'].length >= 10
        }
      });
    }

    set_errorInputs(errorInputs);
    return Object.values(errorInputs).includes(true);
  };

  const enterReservation = e => {
    e.preventDefault();
    if (verifyForm()) return;
    set_loading(true);
    eventService
      .CheckAuditoriumInscription(inputs)
      .then(response => {
        if (response.data.success) {
          set_loading(false);
          let info = {};
          if (response.data.finded && response.data.guest) {
            const age = response.data.guest.age
              ? response.data.guest.age
              : inputs.age;
            info = { ...response.data.guest, ...info, age };
          }

          if (response.data.allReservations)
            info = { allReservations: response.data.allReservations, ...info };

          if (response.data.volunteer)
            info = { ...response.data.volunteer, ...info };

          if (response.data.reserved) {
            info = { ...response.data.reserved, ...info };

            if (response.data.seat) {
              info = { selected: response.data.seat, ...info };
            }
          }
          setInputs({ ...inputs, ...info });

          if (response.data.reserved) {
            setStep('summary');
          } else {
            setStep('FillData');
          }
        }
      })
      .catch(err => {
        set_loading(false);
      });
  };

  React.useEffect(() => {
    if (new Date(inputs.birthdate) == 'Invalid Date' || !inputs.birthdate) {
      const today = new Date();
      const birthYear = new Date(
        `06/01/${parseInt(today.getFullYear()) - parseInt(inputs.age)}`
      );
      setInputs({ ...inputs, birthdate: birthYear });
    }
  }, [inputs.age]);

  const selectHandle = (a, { value, name }) => {
    setInputs({ ...inputs, [name]: value });
  };

  const handleInputs = event => {
    let value = event.target.value;
    let name = event.target.name;
    setInputs({ ...inputs, [name]: value });
  };

  function captchaOnChange(value) {
    setInputs({ ...inputs, captcha: value.split(':').pop() });
  }

  React.useEffect(()=>{
    if (parsedQueryString.evento === "todos") {
      eventService
        .getEventsNames()
        .then(response => {
          if (response.data.success) {
            set_eventsNames(response.data.eventsNames)
          }
        })
        .catch(error => {})
    }
  },[])

  return (
    <div className="service">
      <h1 style={{textAlign:'center'}}>¡Bienvenido!</h1>
      <h2 style={{textAlign:'center'}}> Reserva tu cupo</h2>
      <form onSubmit={e => enterReservation(e)} className="Registration__form">
        <label style={{ display: 'block', padding: '20px', fontSize: '35px' }}>
          Escribe tus datos
        </label>
        <div><small>Reservando para { eventSlugnameTrim(inputs.eventSlugname)}</small></div>
        <p style={clickableStyles} onClick={()=> set_showAvailabilityModal(true)}><small>Ver disponibilidad de los servicios</small></p>
        {showAvailabilityModal && 
          <ServicesAvailabilityModal 
            set_showAvailabilityModal={set_showAvailabilityModal} 
            inputs={inputs}
          />
        }
        <p style={{width:"80%", margin:"20px auto"}}>Aquí podrás reservar tu cupo para los Servicios de Celebración del próximo fin de semana en Comunife Cali.</p>
        {Object.values(errorInputs).includes(true) && (
          <h4 style={{ color: 'red' }}>
            Por favor verifica los campos en rojo.
          </h4>
        )}
        {parsedQueryString.evento === "todos" && <div>
          {formSelectService({
            Select,
            label: 'Auditorio',
            name: 'eventSlugname',
            style: { margin: '0 10px 0 20px' },
            handler:selectHandle,
            value: inputs.eventSlugname || '',
            options: eventsNames
          })}
        </div>}
        <Input
          style={{ width: '33%', margin: '5px' }}
          onChange={handleInputs}
          name={'identification'}
          value={inputs.identification}
          type="number"
          placeholder="Identificación"
          id="identification"
          className={`Registration__input Registration__input-${'identification'} ${
            errorInputs['identification'] ? 'error' : ''
          }`}
        />
        <Input
          placeholder="Celular"
          id="phone"
          name={'phone'}
          type="number"
          className={`Registration__input Registration__input-${'phone'} ${
            errorInputs['phone'] ? 'error' : ''
          }`}
          value={inputs.phone}
          onChange={handleInputs}
          style={{ width: '33%', margin: '5px' }}
        />
        <Input
          style={{ width: '33%', margin: '5px' }}
          onChange={handleInputs}
          type="number"
          name={'age'}
          value={inputs.age}
          placeholder="Edad"
          id="age"
          className={`Registration__input Registration__input-${'age'} ${
            errorInputs['age'] ? 'error' : ''
          }`}
        />
        <div className={`captcha ${errorInputs.captcha ? 'error' : ''}`}>
          <ReCAPTCHA
            style={{ margin: 'auto' }}
            sitekey={process.env.REACT_APP_RECAPTCHA}
            onChange={captchaOnChange}
          />
        </div>
        <p style={{ color: 'red' }}>
          {errorInputs.captcha ? 'Por favor resuelve el captcha.' : ''}
        </p>
        <p style={{ margin: '20px 0', lineHeight: '16px' }}>
          <small>
          En conformidad con la Ley 1581 de 2012 y el Decreto 1377 de 2013, autorizo a la Iglesia Comunidad Cristiana de Fe Cali para el tratamiento de los datos personales que he entregado, 
que serán usados en el desarrollo del objeto social de la Iglesia y con el propósito de mantener una eficiente comunicación enviándome información relacionada con la organización. 
Así mismo, autorizo que la Iglesia Comunidad Cristiana de Fe Cali capte mi imagen, ya sea en video o fotografía, y cedo los derechos sobre la misma para que la use en todos los medios 
que considere apropiados con fines publicitarios institucionales sin modalidad de comercialización, siempre y cuando dicho uso sea conforme a la moral y al orden público. 
Entiendo que podré ejercer mi derecho de conocer, rectificar y cancelar esta información.
          </small>
        </p>
        <Button primary disabled={loading} onClick={e => enterReservation(e)}>
          Entrar
        </Button>
      </form>
    </div>
  );
};

export default ReservationStepCheck;
