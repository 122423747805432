import React from 'react';
import logo from '../../assets/static/logo-comunidad-lark.png';
import { Link } from 'react-router-dom/cjs/react-router-dom';

const Header = () => (
  <header className="header" id="nav">
    <Link to={'/'}>
      <img className="header__img" src={logo} alt="Logo CGL" />
    </Link>
    {localStorage.getItem('userToken') ? (
      <Link to={'/logout'} className="logout">
        <span>Salir</span>
      </Link>
    ) : (
      <Link to="/login" className="logout">
        <span>Login</span>
      </Link>
    )}
  </header>
);

export default Header;
