import proxybase from './proxybase';
import authService from './auth';
class churchForms {
  constructor() {
    this.token = localStorage.getItem('userToken');
    this.volunteerForm = this.volunteerForm.bind(this);
    this.getVolunteerForm = this.getVolunteerForm.bind(this);
  }
  volunteerForm(body) {
    return proxybase
      .post(`/experience/volunteerForm`, body)
      .then(response => {
        return Promise.resolve(response);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }
  getVolunteerForm({ body, serviceArea = '' }) {
    return proxybase
      .post(`/experience/allVolunteers/${serviceArea}`, body, {
        headers: { Authorization: this.token }
      })
      .then(response => {
        return Promise.resolve(response);
      })
      .catch(error => {
        if (error.response.status === 401) authService.logout();
        return Promise.reject(error);
      });
  }
  deleteVolunteerForm({ deleted, id }) {
    return proxybase
      .post(
        `/experience/deleteVolunteerForm`,
        { id, deleted },
        {
          headers: { Authorization: this.token }
        }
      )
      .then(response => {
        return Promise.resolve(response);
      })
      .catch(error => {
        if (error.response.status === 401) authService.logout();
        return Promise.reject(error);
      });
  }
}
export default new churchForms();
