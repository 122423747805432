import React from 'react';
import Button from '../common/Button';
import moment from 'moment-timezone';

const Row = props => {
  const { printQueue, deleteOfQueue } = props;
  const { identification, name, createdAt } = printQueue;

  return (
    <tr>
      <td>{identification}</td>
      <td>{name}</td>
      <td>
        {moment.tz(createdAt, 'America/Bogota').format('DD-MM-YYYY, HH:mm:ss')}
      </td>

      <td>
        <a
          href={`/checkin/print?id=${identification}&name=${name}`}
          target="_blank"
        >
          <Button text={'Imprimir'} />
        </a>
        <Button
          text={'Eliminar'}
          onClick={() => deleteOfQueue(identification)}
        />
      </td>
    </tr>
  );
};

export default Row;
