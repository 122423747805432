import React from 'react';
import authService from '../../services/auth';
import queryString from 'query-string';
import { Icon, Button } from 'semantic-ui-react';

const RegisterModule = props => {
  const currentUser = authService.getCurrentUser();
  let query = props.location.search;
  let parsedQueryString = queryString.parse(query);

  React.useEffect(() => {
    if (document) {
      try {
        document.title = 'Comunidad Cali | Administrar registro';
      } catch (error) {
        console.log(error);
      }
    }
  }, []);

  return (
    <>
      <section className="Home">
        <div className="Home__content">
          <h1 className="Home__title">Bienvenido</h1>
          <p className="Home__intro">
            Bienvenido al modulo de reserva y autenticación de Comunidad
            Cristiana de Fé.
          </p>
        </div>
        <div className="Home__buttons">
          <Button
            disabled={false}
            onClick={() => props.history.push('/servicio/reservacion')}
            primary
          >
            Reserva nueva
          </Button>
          <Button
            onClick={() =>
              props.history.push(
                `/checkin?event=Auditorio_Principal&eventSN=Auditorio_Principal${
                  parsedQueryString.id ? `&id=${parsedQueryString.id}` : ''
                }`
              )
            }
            primary
            disabled={false}
          >
            Ingreso Servicios
          </Button>
        </div>
        {currentUser.profile === 1 && (
          <div className="action_buttons">
            <Button
              secondary
              onClick={() => props.history.push('/church-services')}
            >
              <Icon name="archive" /> Servicios fin de semana
            </Button>
            <Button secondary onClick={() => props.history.push('/all-guests')}>
              <Icon name="archive" /> Buscar Usuarios
            </Button>
            <Button
              secondary
              onClick={() => props.history.push('/all-volunteers')}
            >
              <Icon name="archive" /> Voluntarios
            </Button>
          </div>
        )}
      </section>
    </>
  );
};

export default RegisterModule;
