import React from 'react';

const VolunteerForm = props => {
  const { input, selectArea, selectkindofid } = props;
  return (
    <>
      <div className="main-form">
        <p>
          NOMBRES {input({ input: 'name' })} APELLIDOS{' '}
          {input({ input: 'lastName' })}
        </p>
        <p>
          DOCUMENTO DE IDENTIDAD {selectkindofid()}
          {input({ input: 'identification', type: 'number' })}
        </p>
        <p>
          CELULAR {input({ input: 'cellPhone', type: 'number' })} EMAIL{' '}
          {input({ input: 'email', type: 'email' })}
        </p>
        <p>DIRECCIÓN RESIDENCIA {input({ input: 'address' })}</p>
        <p>Area en la que sirve: {selectArea()}</p>
      </div>
      <div className="minors-form">
        <h3>Sólo diligenciar en caso de ser menor de edad:</h3>
        <p>
          Nombre del Acudiente: {input({ input: 'parentsName' })} Cedula del
          Acudiente: {input({ input: 'parentsIdentification', type: 'number' })}
        </p>
        <p>
          Parentesco: {input({ input: 'kindOfParent' })} Telefono:{' '}
          {input({ input: 'parentsPhone', type: 'number' })}
        </p>
      </div>
    </>
  );
};
export default VolunteerForm;
