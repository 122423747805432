import React from 'react';
import { Button, Modal } from 'semantic-ui-react';
import eventService from '../../../../services/eventService';
import moment from 'moment';
moment.lang('es', {
  months: 'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split(
    '_'
  ),
  monthsShort: 'Enero._Feb._Mar_Abr._May_Jun_Jul._Ago_Sept._Oct._Nov._Dec.'.split(
    '_'
  ),
  weekdays: 'Domingo_Lunes_Martes_Miércoles_Jueves_Viernes_Sábado'.split('_'),
  weekdaysShort: 'Dom._Lun._Mar._Mier._Jue._Vier._Sab.'.split('_'),
  weekdaysMin: 'Do_Lu_Ma_Mi_Ju_Vi_Sa'.split('_')
});

const ServicesAvailabilityModal = props => {
  const { set_showAvailabilityModal, inputs } = props;
  const [services, set_services] = React.useState({});
  const [loading, set_loading] = React.useState(false);

  const getDate = date =>
  moment.tz(date, 'America/Bogota').format('YYYY-MM-DD');

  React.useEffect(() => {
    set_loading(true);

    let eventSlugname = inputs.eventSlugname || 'Auditorio_Principal';

    let body = { eventSlugname, currentWeek: true };
    eventService
      .getAuditoriumEvents(body)
      .then(response => {
        set_loading(false);
        if (response.data.success) {
          let events = {};
          response.data.events.forEach(event => {
            events[getDate(event.date)] = [
              ...(events[getDate(event.date)] || []),
              event
            ];
          });

          set_services(events);
        }
      })
      .catch(err => {
        set_loading(false);
      });
  }, [inputs.eventSlugname]);

  return (
    <Modal size={'fullscreen'} open onClose={() => set_showAvailabilityModal(false)}>
      <Modal.Header>Disponibilidad de los servicios</Modal.Header>
      <Modal.Content>
      <div className="dates">
        {Object.keys(services).map(date => (
          <>
            <p style={{ padding: '20px 0 0 0' }}>
              <strong>{moment.tz(date, 'America/Bogota').format('dddd DD [de] MMMM')}</strong>
            </p>
            {services[date].map(service =>

                <p
                  primary
                  disabled={loading}
                >
                  <strong className="time">
                    {moment
                      .tz(service.date, 'America/Bogota')
                      .format('hh:mm a')}
                  </strong>
                  {': '}
                  {Boolean(service.capacity) && Boolean(service.total) && (
                    <span className="left">
                      quedan{' '}
                      {parseInt(service.capacity) - parseInt(service.total)}{' '}
                      cupos
                    </span>
                  )}
                </p>
              
            )}
          </>
        ))}
      </div>
      {loading && <p>Cargando disponibilidad</p>}
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => set_showAvailabilityModal(false)} negative>
          Cerrar
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ServicesAvailabilityModal;
