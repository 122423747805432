import proxybase from './proxybase';
import authService from './auth';
class guestService {
  constructor() {
    this.token = localStorage.getItem('userToken');
    this.getGuest = this.getGuest.bind(this);
  }
  getGuest(identification, eventURL, body = {}) {
    return proxybase
      .post(`/${eventURL}/${identification}`, body, {
        headers: { Authorization: this.token }
      })
      .then(response => {
        return Promise.resolve(response);
      })
      .catch(error => {
        if (error.response.status === 401) authService.logout();
        return Promise.reject(error);
      });
  }
  getGuests(eventURL, body = {}) {
    return proxybase
      .post(`/${eventURL}`, body, {
        headers: { Authorization: this.token }
      })
      .then(response => {
        return Promise.resolve(response);
      })
      .catch(error => {
        if (error.response.status === 401) authService.logout();
        return Promise.reject(error);
      });
  }
  createAttendance({ body = {}, eventURL }) {
    return proxybase
      .post(`/${eventURL}`, body, {
        headers: { Authorization: this.token }
      })
      .then(response => {
        return Promise.resolve(response);
      })
      .catch(error => {
        if (error.response.status === 401) authService.logout();
        return Promise.reject(error);
      });
  }
  updateGuestItems({ body = {}, eventURL }) {
    return proxybase
      .put(`/${eventURL}`, body, {
        headers: { Authorization: this.token }
      })
      .then(response => {
        return Promise.resolve(response);
      })
      .catch(error => {
        if (error.response.status === 401) authService.logout();
        return Promise.reject(error);
      });
  }
  createGuest(body = {}) {
    return proxybase
      .post(`/users/guest`, body, {
        headers: { Authorization: this.token }
      })
      .then(response => {
        return Promise.resolve(response);
      })
      .catch(error => {
        if (error.response.status === 401) authService.logout();
        return Promise.reject(error);
      });
  }
  getAllGuests(body = {}) {
    return proxybase
      .post(`/users/guests`, body, {
        headers: { Authorization: this.token }
      })
      .then(response => {
        return Promise.resolve(response);
      })
      .catch(error => {
        if (error.response.status === 401) authService.logout();
        return Promise.reject(error);
      });
  }
  updateGuest(id, body) {
    return proxybase
      .put(`/users/guest/${id}`, body, {
        headers: { Authorization: this.token }
      })
      .then(response => {
        return Promise.resolve(response);
      })
      .catch(error => {
        if (error.response.status === 401) authService.logout();
        return Promise.reject(error);
      });
  }
  getCertificate(id, body = {}) {
    return proxybase
      .post(`/get-certificate/${id}`, body)
      .then(response => {
        return Promise.resolve(response);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }
}
export default new guestService();
