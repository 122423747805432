import React from 'react';
import { Button } from 'semantic-ui-react';

const ChurchServiceGuestsRow = props => {
  const { attended, volunteer, eventSlugname } = props.guest;
  const { identification, name, phone, lastName } = props.guest.Guest;

  return (
    <tr>
      <td>
        <p style={{ margin: '0' }}>{identification}</p>
      </td>
      <td>
        <p style={{ margin: '0' }}>
          {name} {lastName}
        </p>
      </td>
      <td>
        <p style={{ margin: '0' }}>{phone}</p>
      </td>
      <td>
        <p style={{ margin: '0' }}>{attended ? 'Si' : 'No'}</p>
      </td>
      <td>
        <p style={{ margin: '0' }}>{volunteer ? volunteer : 'No'}</p>
      </td>
      <td>
        <Button
          onClick={() =>
            props.history.push(
              `checkin?event=Auditorio_Principal&id=${identification}&eventSN=${eventSlugname}`
            )
          }
        >
          Ver usuario
        </Button>
      </td>
    </tr>
  );
};

export default ChurchServiceGuestsRow;
