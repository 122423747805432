import React from 'react';
import { Button, Modal } from 'semantic-ui-react';
import moment from 'moment';
import eventService from '../../../services/eventService';
import Swal from 'sweetalert2';

const ChurchServiceDetails = props => {
  const [open, setOpen] = React.useState(false);
  const [loading, set_loading] = React.useState(false);

  const serviceDate = moment
    .tz(props.date, 'America/Bogota')
    .format('dddd DD [de] MMMM');

  const deleteEvent = id => {
    Swal.fire({
      title: '¿Seguro que desea eliminar este servicio?',
      showCancelButton: true,
      confirmButtonText: 'Si, Eliminar',
      cancelButtonText: 'Volver'
    }).then(result => {
      if (result.value) {
        set_loading(true);
        eventService
          .deleteEvent({ id })
          .then(response => {
            set_loading(false);
            if (response.data.success) {
              props.getEvents();
              Swal.fire(response.data.message);
            }
          })
          .catch(err => {
            set_loading(false);
          });
      }
    });
  };

  const editEvent = async ({ Swal, subtitle = '', func = () => {} }) => {
    const { value: text } = await Swal.fire({
      title: 'Cambiar capacidad',
      input: 'number',
      text: subtitle,
      inputPlaceholder: 'Escribe la nueva capacidad',
      inputAttributes: {
        'aria-label': 'Nueva capacidad'
      },
      showCancelButton: true
    });

    if (text) func({ text });
  };

  const timeName = date => moment.tz(date, 'America/Bogota').format('hh:mm a');

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={
        <p style={{ padding: '20px 0 0 0', cursor: 'pointer' }}>
          {serviceDate}
        </p>
      }
    >
      <Modal.Header>{serviceDate}</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          {props.services.map(service => (
            <div>
              <strong>Hora:</strong> {timeName(service.date)}{' '}
              <strong>Capacidad:</strong> {service.capacity}{' '}
              <span
                onClick={() => deleteEvent(service.id)}
                style={{
                  color: 'red',
                  cursor: 'pointer',
                  textDecorationLine: 'underline'
                }}
              >
                Eliminar
              </span>
              {' - '}
              <span
                onClick={() =>
                  editEvent({
                    subtitle: `${serviceDate} - ${timeName(service.date)}`,
                    Swal,
                    func: ({ text }) => {
                      eventService
                        .editEvent({ id: service.id, capacity: text })
                        .then(response => {
                          set_loading(false);
                          if (response.data.success) {
                            props.getEvents();
                            Swal.fire(response.data.message);
                          }
                        })
                        .catch(err => {
                          set_loading(false);
                        });
                    }
                  })
                }
                style={{
                  color: 'blue',
                  cursor: 'pointer',
                  textDecorationLine: 'underline'
                }}
              >
                Modificar capacidad
              </span>
            </div>
          ))}
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={() => setOpen(false)}
          loading={loading}
          disabled={loading}
        >
          Cerrar
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ChurchServiceDetails;
