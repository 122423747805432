import React from 'react';
import { Button } from 'semantic-ui-react';
import { deleteForm } from '../../helpers/index';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import churchForms from '../../../services/churchForms';

const VolunteersRow = props => {
  const { Volunteer, set_showVolunteer, getInfo, currentUser } = props;

  const {
    id,
    name,
    identification,
    lastName,
    cellPhone,
    serviceArea,
    leaderName,
    leaderPhone
  } = Volunteer;

  return (
    <tr>
      <td>
        <p style={{ margin: '0' }}>{identification}</p>
      </td>
      <td>
        <p style={{ margin: '0' }}>{name}</p>
      </td>
      <td>
        <p style={{ margin: '0' }}>{lastName}</p>
      </td>
      <td>
        <p style={{ margin: '0' }}>{cellPhone}</p>
      </td>
      <td>
        <p style={{ margin: '0' }}>{serviceArea}</p>
      </td>
      <td>
        <Button primary onClick={() => set_showVolunteer(id)}>
          Ver
        </Button>
      </td>
      {currentUser.status.includes('totalAccess') && (
        <td>
          <Button
            onClick={() =>
              deleteForm({
                subtitle: name,
                Swal,
                func: ({ text, successAlert }) => {
                  churchForms
                    .deleteVolunteerForm({ id, deleted: text })
                    .then(response => {
                      if (response.data.success) {
                        successAlert();
                        getInfo();
                      }
                    })
                    .catch(error => {
                      if (error.response)
                        Swal.fire(error.response.data.message);
                    });
                }
              })
            }
          >
            Eliminar
          </Button>
        </td>
      )}
    </tr>
  );
};

export default VolunteersRow;
