import React from 'react';
import { Button, Checkbox, Select } from 'semantic-ui-react';
import eventService from '../../../services/eventService';
import CreateService from './createService';
import moment from 'moment';
import ChurchServiceGuests from './churchServiceGuests';
import Swal from 'sweetalert2';
import ChurchServiceDetails from './serviceDetails';
import ServicesReport from './servicesReport';
import { formSelectService } from '../../../components/helpers';
import { DateRangePicker } from 'react-dates';
import GenerateLinksModal from './generateLinksModal';

moment.lang('es', {
  months: 'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split(
    '_'
  ),
  monthsShort: 'Enero._Feb._Mar_Abr._May_Jun_Jul._Ago_Sept._Oct._Nov._Dec.'.split(
    '_'
  ),
  weekdays: 'Domingo_Lunes_Martes_Miércoles_Jueves_Viernes_Sábado'.split('_'),
  weekdaysShort: 'Dom._Lun._Mar._Mier._Jue._Vier._Sab.'.split('_'),
  weekdaysMin: 'Do_Lu_Ma_Mi_Ju_Vi_Sa'.split('_')
});

const ViewAllServices = props => {
  const [loading, set_loading] = React.useState(false);
  const [services, set_services] = React.useState({});
  const [inputs, setInputs] = React.useState({eventSlugname: 'Auditorio_Principal'});
  const [serviceAttendance, set_serviceAttendance] = React.useState([]);
  const [clickedEvent, set_clickedEvent] = React.useState({});
  const [handleDate, set_handleDate] = React.useState({
    startDate: moment(new Date()).tz('America/Bogota').startOf('day').isoWeekday(1), 
    endDate: moment(new Date()).tz('America/Bogota').startOf('day').isoWeekday(8)
  });
  const [focused, set_focused] = React.useState({});
  const [eventsNames, set_eventsNames] = React.useState([]);
  
  const getDate = date =>
    moment.tz(date, 'America/Bogota').format('YYYY-MM-DD');

  React.useEffect(() => {
    if (eventsNames.includes(inputs.eventSlugname))
      getEvents();
  }, [inputs.eventSlugname]);

  React.useEffect(() => {
    getEvents();
  }, []);

  const selectHandle = (a, { value, name }) => {
    setInputs({ ...inputs, [name]: value });
  };

  React.useEffect(() => {
    if (inputs.startDate && inputs.endDate) {
      getEvents();
    }
  }, [inputs.startDate, inputs.endDate]);

  const getEvents = () => {
    const { eventSlugname, startDate, endDate } = inputs
    set_loading(true);
    let body = { eventSlugname, startDate, endDate };
    eventService
      .getAuditoriumEvents(body)
      .then(response => {
        if (response.data.success) {
          set_loading(false);
          let events = {};
          response.data.events.forEach(event => {
            events[getDate(event.date)] = [
              ...(events[getDate(event.date)] || []),
              event
            ];
          });
          set_services(events);
        }
      })
      .catch(err => {
        set_loading(false);
      });
  };

  const getEvent = id => {
    set_loading(true);
    eventService
      .eventAttendance({ id })
      .then(response => {
        set_loading(false);
        if (response.data.success) {
          if (response.data.event.length) {
            set_serviceAttendance(response.data.event);
          } else {
            Swal.fire('No hay usuarios registrados a este servicio');
          }
        }
      })
      .catch(err => {
        set_loading(false);
      });
  };

  const handleDateChange = (startDate, endDate) => {
    setInputs({
      ...inputs,
        startDate: startDate ? startDate._d.getTime() : null,
        endDate: endDate ? endDate._d.getTime() : null
    });
    set_handleDate({ startDate, endDate });
  };

  React.useEffect(()=>{
    getEventsNames()
  },[])
  
  const getEventsNames = () => {
    eventService
    .getEventsNames()
    .then(response => {
      if (response.data.success) {
        set_eventsNames(response.data.eventsNames)
      }
    })
    .catch(error => {})
  }

  return (
    <div className="service selectAService">
      <h1
        style={{
          display: 'block',
          padding: '20px',
          textAlign: 'center',
          fontSize: '45px'
        }}
      >
        Servicios
      </h1>
      {serviceAttendance.length === 0 ? (
        <>
          <CreateService
            inputs={inputs}
            eventsNames={eventsNames}
            setInputs={setInputs}
            getEventsNames={getEventsNames}
            getEvents={getEvents}
          />
          <ServicesReport eventsNames={eventsNames} />
          <GenerateLinksModal eventsNames={eventsNames} />
          <div style={{ padding: '20px 0 0 0' }}>
            {formSelectService({
              Select,
              label: 'Auditorio',
              name: 'eventSlugname',
              handler: selectHandle,
              value: inputs.eventSlugname || '',
              options: eventsNames
            })}
          </div>
          <div style={{ padding: '20px 0 0 0' }}>
            <DateRangePicker
              startDatePlaceholderText="Fecha Inicio"
              endDatePlaceholderText="Fecha Fin"
              startDate={handleDate.startDate}
              startDateId="startDateRange"
              minimumNights={0}
              endDate={handleDate.endDate}
              endDateId="endDateRange"
              onDatesChange={({ startDate, endDate }) =>
                handleDateChange(startDate, endDate)
              }
              focusedInput={focused}
              onFocusChange={focused => set_focused(focused)}
              isOutsideRange={() => false}
            />
          </div>
          <label
            style={{
              display: 'block',
              padding: '20px 0px 20px 0px',
              fontSize: '25px'
            }}
          >
            Selecciona el servicio que quieres visualizar
          </label>
          {!loading && Object.keys(services).length === 0 && (
             <p>No hay servicios creados{inputs.startDate && inputs.endDate ? " en las fechas seleccionadas." : " esta semana."}</p>
          )}
          {loading && <p>Cargando servicio...</p>}
          <div className="dates">
            {Object.keys(services).map(date => (
              <>
                <ChurchServiceDetails
                  getEvents={getEvents}
                  date={date}
                  services={services[date]}
                />
                {services[date].map(service => (
                  <Button
                    primary
                    disabled={loading}
                    onClick={e => {
                      getEvent(service.id);
                      set_clickedEvent(service);
                    }}
                  >
                    <div className="time">
                      {moment
                        .tz(service.date, 'America/Bogota')
                        .format('hh:mm a')}
                    </div>
                    {Boolean(service.capacity) && Boolean(service.total) && (
                      <div className="center">
                        Total {parseInt(service.total)}
                      </div>
                    )}
                  </Button>
                ))}
              </>
            ))}
          </div>
        </>
      ) : (
        <ChurchServiceGuests
          set_serviceAttendance={set_serviceAttendance}
          serviceAttendance={serviceAttendance}
          clickedEvent={clickedEvent}
          history={props.history}
        />
      )}
    </div>
  );
};

export default ViewAllServices;
