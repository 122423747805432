import React from 'react';
import Button from '../components/common/Button';
import printQueueService from '../services/printQueue';
import Row from '../components/printQueue/row';
import Swal from 'sweetalert2/dist/sweetalert2.js';

const PrintQueueTable = props => {
  const [printQueue, set_printQueue] = React.useState([{ GLs: [{}] }]);
  const [loaded, set_loaded] = React.useState(false);

  React.useEffect(() => {
    getprintQueue();
  }, []);

  function getprintQueue() {
    set_loaded(false);
    printQueueService
      .getPrintQueue()
      .then(response => {
        if (response.data.success) {
          set_loaded(true);
          set_printQueue(response.data.printQueue);
        }
      })
      .catch(error => {
        if (error.response) Swal.fire(error.response.data.message);
        set_loaded(false);
      });
  }

  function updateAPrintQueue(identification) {
    printQueueService
      .updateAPrintQueue(identification)
      .then(response => {
        if (response.data.success) {
          getprintQueue();
        }
      })
      .catch(error => {
        if (error.response) Swal.fire(error.response.data.message);
        set_loaded(false);
      });
  }

  return (
    <section className="AdminTable">
      <div className="AdminTable__content">
        <Button text={'Recargar'} onClick={() => getprintQueue()} />
      </div>
      {loaded ? (
        <div>
          <h2>Cola de Impresión</h2>
          <table>
            <thead>
              <tr>
                <th>Cedula</th>
                <th>Nombre</th>
                <th>Creado</th>
                <th>Opciones</th>
              </tr>
            </thead>
            <tbody>
              {printQueue.length ? (
                printQueue.map(element => (
                  <Row
                    printQueue={element}
                    deleteOfQueue={updateAPrintQueue}
                    history={props.history}
                  />
                ))
              ) : (
                <p>No hay elementos para imprimir</p>
              )}
            </tbody>
          </table>
        </div>
      ) : (
        <p>Cargando</p>
      )}
    </section>
  );
};

export default PrintQueueTable;
