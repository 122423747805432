import React from 'react';
import { Button, Modal } from 'semantic-ui-react';
import Form from '../../form/Form';
import guestService from '../../../services/guest';
import Swal from 'sweetalert2';

const GuestModal = props => {
  const [Attendant, set_Attendant] = React.useState({});
  const [errorData, set_errorData] = React.useState(null);
  const { guest, set_show_modal, getInfo } = props;
  const [loadedData, set_loadedData] = React.useState(false);

  React.useEffect(() => {
    set_Attendant(guest);
  }, [guest]);

  const handleInputs = event => {
    let value = event.target.value;
    let name = event.target.name;
    set_Attendant({ ...Attendant, [name]: value });
  };

  const handleDropDown = ({ name, value }) => {
    set_Attendant({ ...Attendant, [name]: value });
  };

  React.useEffect(() => {
    setTimeout(() => {
      set_errorData(null);
    }, 6000);
  }, [errorData]);

  function updateAttendant(e) {
    e.preventDefault();

    const {
      id,
      lastName,
      name,
      phone,
      identification,
      email,
      gender,
      age
    } = Attendant;

    if (!name || !lastName || !phone || !identification || !gender || !age) {
      set_errorData('Todos los campos son obligatorios');
      return;
    }
    set_loadedData(true);
    guestService
      .updateGuest(id, {
        name,
        lastName,
        phone,
        identification,
        email,
        gender,
        age
      })
      .then(response => {
        if (response.data.success) {
          Swal.fire(response.data.message);
          set_loadedData(false);
          getInfo();
        }
      })
      .catch(error => {
        set_errorData(error.response.data.message);
        set_loadedData(false);
      });
  }

  return (
    <Modal size={'fullscreen'} open onClose={() => set_show_modal(false)}>
      <Modal.Header>Ver participante</Modal.Header>
      <Modal.Content>
        <form className="Registration__form">
          <Form
            saveTag={'Guardar'}
            Attendant={Attendant}
            userprofile={true}
            handleInputs={handleInputs}
            handleDropDown={handleDropDown}
            errorInputs={{}}
            showAge
            showGender
            sendInForm
            loadedData={loadedData}
            submit={updateAttendant}
          />
        </form>
      </Modal.Content>

      <Modal.Actions>
        <Button onClick={() => set_show_modal(false)} negative>
          Cancelar
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default GuestModal;
