import React from 'react';
import moment from 'moment-timezone';
import { Input, Select, Button } from 'semantic-ui-react';
import { formInput } from '../helpers';
import { SingleDatePicker } from 'react-dates';
moment.lang('es', {
  months: 'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split(
    '_'
  ),
  monthsShort: 'Enero._Feb._Mar_Abr._May_Jun_Jul._Ago_Sept._Oct._Nov._Dec.'.split(
    '_'
  ),
  weekdays: 'Domingo_Lunes_Martes_Miércoles_Jueves_Viernes_Sábado'.split('_'),
  weekdaysShort: 'Dom._Lun._Mar._Mier._Jue._Vier._Sab.'.split('_'),
  weekdaysMin: 'Do_Lu_Ma_Mi_Ju_Vi_Sa'.split('_')
});

const Form = props => {
  const {
    sendInForm,
    handleInputs,
    submit,
    loadedData,
    Attendant,
    errorInputs,
    showGender,
    showAge,
    showDate,
    showBirthdate,
    showleaderName,
  } = props;
  const {
    lastName,
    name,
    phone,
    identification,
    createdAt,
    email,
    gender,
    age,
    birthdate,
    leaderName,
    attendingGroup
  } = Attendant || {};
  const [focused, set_focused] = React.useState(false);

  const formSelect = ({
    name,
    label,
    value,
    options,
    handler = selectHandle
  }) => (
      <Select
        placeholder={label}
        className={errorInputs[name] ? 'error' : ''}
        onChange={handler}
        value={value}
        name={name}
        options={options.map(a => {
          return { key: a, value: a, text: a };
        })}
      />
    );

  const selectHandle = (a, { value, name }) =>
    handleInputs({ target: { value, name } });

  const renderMonthElement = ({ month, onMonthSelect, onYearSelect }) => {
    let i
    let years = []
    for (i = moment().year(); i >= moment().year() - 100; i--) {
      years.push(<option value={i} key={`year-${i}`}>{i}</option>)
    }
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div>
          <select value={month.month()} onChange={e => onMonthSelect(month, e.target.value)}>
            {moment.months().map((label, value) => (
              <option value={value} key={value}>{label}</option>
            ))}
          </select>
        </div>
        <div>
          <select value={month.year()} onChange={e => onYearSelect(month, e.target.value)}>
            {years}
          </select>
        </div>
      </div>
    )
  }
  return (
    <>
      {formInput({
        Input,
        handler: handleInputs,
        label: 'Nombres',
        name: 'name',
        value: name || '',
        errorInputs
      })}

      {formInput({
        Input,
        handler: handleInputs,
        label: 'Apellidos',
        name: 'lastName',
        value: lastName || '',
        errorInputs
      })}

      {formInput({
        Input,
        handler: handleInputs,
        type: 'number',
        label: 'Identificación',
        name: 'identification',
        value: identification || '',
        errorInputs
      })}

      {formInput({
        Input,
        handler: handleInputs,
        label: 'Celular',
        name: 'phone',
        value: phone || '',
        errorInputs,
        type: 'number'
      })}

      {formInput({
        Input,
        handler: handleInputs,
        label: 'Correo Electrónico',
        name: 'email',
        value: email || '',
        errorInputs,
        type: 'email'
      })}

      {showGender &&
        formSelect({
          label: 'Genero',
          name: 'gender',
          value: gender || '',
          errorInputs,
          options: ['Masculino', 'Femenino']
        })}

      {showAge &&
        formInput({
          Input,
          handler: handleInputs,
          label: 'Edad',
          name: 'age',
          value: age || '',
          errorInputs,
          type: 'number'
        })}

      {showleaderName && attendingGroup &&
        formInput({
          Input,
          handler: handleInputs,
          label: 'Nombre del Lider',
          name: 'leaderName',
          value: leaderName || '',
          errorInputs,
          type: 'leaderName'
        })}

      {showBirthdate && (
        <div>
          <label htmlFor={'birthdate'}>Fecha de nacimiento</label>{' '}
          <SingleDatePicker
            date={
              new Date(birthdate) == 'Invalid Date' || !birthdate
                ? null
                : moment(birthdate)
            }
            onDateChange={newDate =>
              handleInputs({
                target: {
                  value: newDate ? newDate._d : newDate,
                  name: 'birthdate'
                }
              })
            }
            focused={focused}
            onFocusChange={({ focused }) => set_focused(focused)}
            placeholder="dd/mm/aaaa"
            showDefaultInputIcon={true}
            numberOfMonths={1}
            displayFormat="DD/MM/YYYY"
            isOutsideRange={() => false}
            id="birthdate"
            renderMonthElement={renderMonthElement}
          />{' '}
          {new Date(birthdate) == 'Invalid Date' || !birthdate
            ? null
            : moment(birthdate).format('DD-MMMM-YYYY')}
          <p>
            <small>
              Llena tu fecha de nacimiento en números así: dd/mm/aaaa
              (día/mes/año), ejemplo: 31/12/2000
            </small>
          </p>
        </div>
      )}
      {createdAt && showDate && (
        <p style={{ display: 'inline-block' }}>
          Creado:{' '}
          {moment
            .tz(createdAt, 'America/Bogota')
            .format('DD-MM-YYYY, h:mm:ss a')}
        </p>
      )}
      {sendInForm && (
        <Button
          disabled={loadedData}
          loading={loadedData}
          onClick={e => submit(e)}
          primary
        >
          {props.saveTag}
        </Button>
      )}
    </>
  );
};
export default Form;