import React from 'react';
import ReactDOM from 'react-dom';
import 'sweetalert2/src/sweetalert2.scss';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import App from './routes/App';

const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN

Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [
      new Integrations.BrowserTracing(),
    ],
  
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.1,
  });

ReactDOM.render(<App />, document.getElementById('App'));
