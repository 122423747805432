import React from 'react';
import SeatPicker from 'react-seat-picker';
import Swal from 'sweetalert2';
import { Button } from 'semantic-ui-react';
import eventService from '../../../../services/eventService';

export default class SeatMap extends React.Component {
  state = {
    loading: true,
    selected: {},
    seatMap: [],
    rechargeSeat: true,
    error: 20
  };

  verifyForm = () => {
    if (this.props.inputs.selected && this.props.inputs.selected.column) {
      this.setState({ error: 20 });
      return false;
    }
    this.setState({ error: this.state.error + 1 });
    return true;
  };

  sendForm = e => {
    e.preventDefault();
    if (this.verifyForm()) return;
    this.setState({ loading: true });
    eventService
      .MakeAReservation(this.props.inputs)
      .then(response => {
        if (response.data.success) {
          this.setState({ loading: false });
          if (response.data.created) {
            this.props.setStep('summary');
          } else {
            this.getSeatMap({
              eventSlugname: 'Auditorio_Principal',
              event_id: this.props.inputs.service.id
            });
            Swal.fire(
              'Este asiento ya esta reservado. por favor selecciona otro.'
            );
          }
        } else {
          Swal.fire(response.data.message);
        }
      })
      .catch(err => {
        this.setState({ loading: false });
      });
  };

  componentDidMount() {
    if (this.props.inputs.service)
      this.getSeatMap({
        eventSlugname: 'Auditorio_Principal',
        event_id: this.props.inputs.service.id
      });
  }

  getSeatMap = ({ eventSlugname, event_id }) => {
    const { setInputs, inputs } = this.props;

    eventService
      .getSiteMap({ eventSlugname, event_id })
      .then(response => {
        if (response.data.success) {
          let seatMap = {};
          response.data.seatMap.forEach(seat => {
            let isReserved = !seat.status || seat['AuditoriumGuests.id'];
            seatMap[seat.row] = [
              ...(seatMap[seat.row] || []),
              {
                id: seat.id,
                isReserved,
                number: `${seat.row}${seat.column}`
              }
            ];
          });
          this.setState(
            {
              loading: false,
              seatMap: Object.values(seatMap),
              rechargeSeat: false
            },
            () => this.setState({ rechargeSeat: true })
          );
          setInputs({ ...inputs, selected: {} });
        }
      })
      .catch(error => {
        if (error.response)
          Swal.fire({
            title: error.response.data.message
          });
      });
  };

  addSeatCallback = ({ row, number, id }, addCb) => {
    const { setInputs, inputs } = this.props;

    this.setState(
      {
        loading: true
      },
      () => {
        addCb(row, number, id);
        setInputs({
          ...inputs,
          selected: { column: number.slice(1), row, id }
        });
        this.setState({ loading: false });
      }
    );
  };

  removeSeatCallback = ({ row, number, id }, removeCb) => {
    const { setInputs, inputs } = this.props;

    this.setState(
      {
        loading: true
      },
      () => {
        removeCb(row, number);
        setInputs({ ...inputs, selected: {} });
        this.setState({ loading: false });
      }
    );
  };

  render() {
    const { loading, seatMap } = this.state;
    const { inputs } = this.props;

    return (
      <div className="service seatmap">
        <h1>Asientos</h1>
        {inputs.selected && inputs.selected.row ? (
          <>
            <p className="choosed">
              Seleccionado: {inputs.selected.row}
              {inputs.selected.column}
            </p>
            <small>Deselecciona el asiento para seleccionar otro.</small>
          </>
        ) : (
          <p
            style={
              this.state.error !== 20
                ? { color: 'red', fontSize: this.state.error }
                : {}
            }
          >
            Selecciona un asiento
          </p>
        )}
        {this.state.rechargeSeat && (
          <div>
            <SeatPicker
              addSeatCallback={this.addSeatCallback}
              removeSeatCallback={this.removeSeatCallback}
              rows={seatMap}
              maxReservableSeats={1}
              alpha
              visible
              selectedByDefault={true}
              loading={loading}
              tooltipProps={{ multiline: true }}
            />
          </div>
        )}
        <Button
          secundary
          disabled={loading}
          onClick={e => {
            this.props.setInputs({ ...inputs, service: null });
            this.props.setStep('selectAService');
          }}
        >
          Volver
        </Button>
        <Button primary disabled={loading} onClick={e => this.sendForm(e)}>
          Finaliza tu reserva
        </Button>
      </div>
    );
  }
}
