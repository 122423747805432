import React from 'react';
import { Modal } from 'semantic-ui-react';

const ReservationStayHome = () => (
  <Modal open>
    <Modal.Header>Disculpanos</Modal.Header>
    <Modal.Content>
      <Modal.Description>
        <p>
          No puedes asistir a nuestro servicio porque no cumples con las
          condiciones minimas de seguridad.
        </p>
        <p>Te invitamos a que te conectes a nuestro campus virtual.</p>
        <p>
          <a target="_blank" href="https://comunifecali.online.church/">
            https://comunifecali.online.church/
          </a>
        </p>
      </Modal.Description>
    </Modal.Content>
  </Modal>
);

export default ReservationStayHome;
