import React from 'react';
import VolunteerRegisterText from '../volunteer/VolunteerRegisterText';
import SignatureCanvas from 'react-signature-canvas';
import logoBlue from '../../assets/static/logo-blue.png';
import VolunteerForm from '../volunteer/VolunteerForm';
import ReactToPrint from 'react-to-print';
import { Button } from 'semantic-ui-react';

const VolunteerRegisterFilled = props => {
  const { inputs } = props;
  //const [errorInputs, set_errorInputs] = React.useState({});
  let errorInputs = {};

  let printable = React.createRef();
  let styles = { inputFilled: { fontWeight: 'initial', fontStyle: 'normal' } };

  const input = ({ input }) => (
    <span style={styles.inputFilled}>{inputs[input]}</span>
  );

  const selectkindofid = () => (
    <span style={styles.inputFilled}>{inputs.kindofIdentification}: </span>
  );

  const selectArea = () => inputs['serviceArea'];

  const dateOfSign = new Date(inputs['createdAt']);

  const printButton = () => (
    <ReactToPrint
      trigger={() => (
        <a href="#" onClick={e => e.preventDefault()}>
          Imprimir Información de {inputs.name} {inputs.lastName}
        </a>
      )}
      content={() => printable.current}
    />
  );
  return (
    <>
      {printButton()}
      <Button
        primary
        compact
        onClick={() => window.location.replace('/all-volunteers')}
        style={{ display: 'block', margin: '10px 0' }}
      >
        Ir Atrás
      </Button>
      <div className="volunteer-registration" id="show" ref={printable}>
        <div className="inner">
          <header>
            <img src={logoBlue} alt="logo" />
            <h2>VOLUNTARIO</h2>
          </header>

          <VolunteerForm
            input={input}
            selectkindofid={selectkindofid}
            errorInputs={errorInputs}
            selectArea={selectArea}
          />
        </div>
      </div>
      {printButton()}
    </>
  );
};
export default VolunteerRegisterFilled;
