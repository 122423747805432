import React from 'react';
import { Button, Modal, Input, Select } from 'semantic-ui-react';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';
import eventService from '../../../services/eventService';
import { errorInput, formSelectService } from '../../../components/helpers';

const CreateService = props => {
  const { inputs, setInputs, getEvents, eventsNames, getEventsNames } = props;
  const { date, capacity } = inputs;
  const [open, setOpen] = React.useState(false);
  const [focused, set_focused] = React.useState(null);
  const [loading, set_loading] = React.useState(false);
  const [newName, set_newName] = React.useState(false);
  const [errorInputs, set_errorInputs] = React.useState({});

  const handleInputs = event => {
    let value = event.target.value;
    let name = event.target.name;
    setInputs({ ...inputs, [name]: value });
  };

  const handleEventNameInput = event => {
    let value = event.target.value.replace(/ /g,"_");
    let name = event.target.name;
    setInputs({ ...inputs, [name]: value });
  };

  const selectHandle = (a, { value, name }) => {
    setInputs({ ...inputs, [name]: value });
  };

  const verifyForm = () => {
    const mandatoryInputs = ['capacity', 'date', 'time', 'eventSlugname'];

    let errorInputs = {};

    mandatoryInputs.forEach(input => {
      errorInputs = errorInput({
        errorInputs,
        input: { name: input, value: inputs[input] }
      });
    });

    set_errorInputs(errorInputs);
    return Object.values(errorInputs).includes(true);
  };

  const createEvent = () => {
    if (verifyForm()) return;
    set_loading(true);

    let body = {
      ...inputs,
      date: `${moment(inputs.date).format('YYYY-MM-DD')} ${
        inputs.time
      }:00 -05:00`,
      kidsEvent: inputs.eventSlugname === "Kids"
    };

    eventService
      .createEvent(body)
      .then(response => {
        set_loading(false);
        if (response.data.success) {
          setOpen(false);
          getEvents();
          getEventsNames()
        }
      })
      .catch(err => {
        set_loading(false);
      });
  };

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={<Button secondary>Crear Servicio</Button>}
    >
      <Modal.Header>Crear un servicio</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Input
            style={{ width: '33%', margin: '5px 5px 5px 0' }}
            onChange={handleInputs}
            name={'capacity'}
            value={capacity}
            type="number"
            placeholder="Capacidad máxima"
            id="capacity"
            className={`Registration__input Registration__input-${'capacity'} ${
              errorInputs['capacity'] ? 'error' : ''
            }`}
          />

          <Select
            placeholder="Hora"
            style={{
              display: 'inline-block',
              width: '35%',
              margin: '7px'
            }}
            value={inputs.time}
            onChange={selectHandle}
            name="time"
            className={`Registration__input Registration__input-${'time'} ${
              errorInputs['time'] ? 'error' : ''
            }`}
            options={[
              6,
              7,
              8,
              9,
              10,
              11,
              12,
              13,
              14,
              15,
              16,
              17,
              18,
              19,
              20,
              21,
              22
            ].map(a => {
              return { key: a, value: a, text: `${a}:00` };
            })}
          />
          <div>
            {newName ? <Input
              style={{ margin: '0 10px 0 0' }}
              onChange={handleEventNameInput}
              name={'eventSlugname'}
              value={inputs.eventSlugname.replace(/_/g," ")}
              placeholder="Nombre del evento"
              id="eventSlugname"
              className={`Registration__input Registration__input-${'eventSlugname'} ${
                errorInputs['eventSlugname'] ? 'error' : ''
              }`}
              /> :
              formSelectService({
                Select,
                label: 'Auditorio',
                name: 'eventSlugname',
                style: { margin: '0 10px 0 20px' },
                handler:selectHandle,
                value: inputs.eventSlugname || '',
                options: eventsNames
              })}
              {!newName && <Button onClick={()=> set_newName(true)}>Nuevo nombre</Button>}
          </div>
          <div
            style={{
              display: 'inline-block',
              padding: '0 0 0 20px',
              margin: '7px'
            }}
          >
            <label style={{ display: 'block' }} htmlFor={'date'}>
              Fecha
            </label>{' '}
            <SingleDatePicker
              date={
                new Date(date) == 'Invalid Date' || !date ? null : moment(date)
              }
              onDateChange={newDate =>
                handleInputs({
                  target: {
                    value: newDate ? newDate._d : newDate,
                    name: 'date'
                  }
                })
              }
              focused={focused}
              onFocusChange={({ focused }) => set_focused(focused)}
              placeholder="dd/mm/aaaa"
              showDefaultInputIcon={true}
              numberOfMonths={1}
              displayFormat="DD/MM/YYYY"
              isOutsideRange={() => false}
              id="date"
              className={`Registration__input Registration__input-${'date'} ${
                errorInputs['date'] ? 'error' : ''
              }`}
            />{' '}
            {new Date(date) == 'Invalid Date' || !date
              ? null
              : moment(date).format('DD-MMMM-YYYY')}
            {}
          </div>
          {Object.values(errorInputs).includes(true) && (
            <h4 style={{ color: 'red' }}>
              Por favor verifica los campos en rojo.
            </h4>
          )}
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button disabled={loading} onClick={() => setOpen(false)}>
          Cancelar
        </Button>
        <Button
          content="Crear"
          disabled={loading}
          loading={loading}
          primary
          onClick={() => createEvent(false)}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default CreateService;
