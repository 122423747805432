import proxybase from './proxybase';
import authService from './auth';
class printQueueService {
  constructor() {
    this.token = localStorage.getItem('userToken');
    this.getPrintQueue = this.getPrintQueue.bind(this);
    this.addToAPrintQueue = this.addToAPrintQueue.bind(this);
  }
  getPrintQueue(body = {}) {
    return proxybase
      .post(
        `/event/getPrintQueue`,
        body,
        {
          headers: { Authorization: this.token }
        },
        {}
      )
      .then(response => {
        return Promise.resolve(response);
      })
      .catch(error => {
        if (error.response.status === 401) authService.logout();
        return Promise.reject(error);
      });
  }
  addToAPrintQueue(body) {
    return proxybase
      .post(`/event/addToAPrintQueue`, body, {
        headers: { Authorization: this.token }
      })
      .then(response => {
        return Promise.resolve(response);
      })
      .catch(error => {
        if (error.response.status === 401) authService.logout();
        return Promise.reject(error);
      });
  }
  updateAPrintQueue(identification, body = {}) {
    return proxybase
      .post(`/event/updateAPrintQueue/${identification}`, body, {
        headers: { Authorization: this.token }
      })
      .then(response => {
        return Promise.resolve(response);
      })
      .catch(error => {
        if (error.response.status === 401) authService.logout();
        return Promise.reject(error);
      });
  }
}
export default new printQueueService();
