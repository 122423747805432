import React from 'react';
import { Button, Checkbox, Modal, Select } from 'semantic-ui-react';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';
import reportService from '../../../services/reportService';
import { formSelectService } from '../../../components/helpers';
import eventService from '../../../services/eventService';

const ServicesReport = props => {
  const [inputs, setInputs] = React.useState({ attended: true });
  const [open, setOpen] = React.useState(false);
  const [focused, set_focused] = React.useState(null);
  const [loading, set_loading] = React.useState(false);

  const handleInputs = event => {
    let value = event.target.value;
    let name = event.target.name;
    setInputs({ ...inputs, [name]: value });
  };

  const selectHandle = (a, { value, name }) => {
    setInputs({ ...inputs, [name]: value });
  };

  function getAttendantReport() {
    set_loading(true);

    let filters = {
      eventSlugname: inputs.eventSlugname,
      rangeDate: inputs.date,
      cancellations: inputs.cancellations,
      volunteer: inputs.volunteer,
      attended: inputs.attended
    };

    let body = { ...filters };
    let URL = `/event/serviceReport`;
    let fileName = `InformeAsistenciaServicios.xlsx`;

    reportService
      .getReport({ URL, fileName, body })
      .then(response => {
        set_loading(false);
      })
      .catch(error => {
        //if (error.response) Swal.fire(error.response.data.message);
        set_loading(false);
      });
  }

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={<Button>Descargar reporte</Button>}
    >
      <Modal.Header>Descargar reporte de servicios</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <h2>Filtros</h2>
          <p>
            <small>Si no seleccionas un filtro se descarga completo</small>
          </p>
          <div>
            {formSelectService({
              Select,
              label: 'Auditorio',
              name: 'eventSlugname',
              handler: selectHandle,
              value: inputs.eventSlugname || '',
              options: props.eventsNames
            })}
          </div>
          <div
            style={{
              display: 'inline-block',
              margin: '7px'
            }}
          >
            <label htmlFor={'date'}>Semana</label>{' '}
            <SingleDatePicker
              date={
                new Date(inputs.date) == 'Invalid Date' || !inputs.date
                  ? null
                  : moment(inputs.date)
              }
              onDateChange={newDate =>
                handleInputs({
                  target: {
                    value: newDate ? newDate._d : newDate,
                    name: 'date'
                  }
                })
              }
              focused={focused}
              onFocusChange={({ focused }) => set_focused(focused)}
              placeholder="dd/mm/aaaa"
              showDefaultInputIcon={true}
              numberOfMonths={1}
              displayFormat="DD/MM/YYYY"
              isOutsideRange={() => false}
              id="date"
            />{' '}
            {new Date(inputs.date) == 'Invalid Date' || !inputs.date
              ? null
              : moment(inputs.date).format('DD-MMMM-YYYY')}
            {}
          </div>
          <div style={{ width: '100%' }}>
            <Checkbox
              label="Filtrar por voluntarios"
              name="volunteer"
              checked={inputs.volunteer}
              toggle
              onClick={() =>
                setInputs({ ...inputs, volunteer: !inputs.volunteer })
              }
            />
          </div>
          <div style={{ width: '100%' }}>
            <Checkbox
              label="Filtrar por asistencia"
              name="attended"
              checked={inputs.attended}
              toggle
              onClick={() => 
                setInputs({ ...inputs, attended: !inputs.attended, cancellations: false })
              }
            />
          </div>
          <div style={{ width: '100%' }}>
            <Checkbox
              label="Filtrar por cancelaciones"
              name="cancellations"
              checked={inputs.cancellations}
              toggle
              onClick={() =>
                setInputs({ ...inputs,cancellations: !inputs.cancellations, attended: false })
              }
            />
          </div>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          disabled={loading}
          loading={loading}
          onClick={() => {
            setOpen(false);
            setInputs({});
          }}
        >
          Cerrar
        </Button>
        <Button
          style={{ margin: '5px' }}
          secondary
          disabled={!inputs.eventSlugname || !inputs.date}
          loading={loading}
          onClick={() => getAttendantReport()}
        >
          Descargar reporte
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ServicesReport;
