import React from 'react';
import Form from '../../components/form/Form';
import guestService from '../../services/guest';
import printQueueService from '../../services/printQueue';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import authService from '../../services/auth';
import { Button } from 'semantic-ui-react';
import EntryCheckpoint from '../church-members/church-service/reservation/entryCheckpoint';

const GuestData = props => {
  const {
    Attendant,
    handleInputs,
    resetForm,
    set_errorData,
    errorData,
    set_Attendant,
    handleDropDown
  } = props;
  const currentUser = authService.getCurrentUser();
  const [showForm, set_showForm] = React.useState(false);

  function updateAttendant(e) {
    e.preventDefault();

    const {
      name,
      lastName,
      phone,
      identification,
      email,
      age,
      gender
    } = Attendant;

    if (!name || !lastName || !phone || !identification) {
      set_errorData('Todos los campos son obligatorios');
      return;
    }

    guestService
      .updateGuest(Attendant.id, {
        name,
        lastName,
        phone,
        identification,
        email,
        age,
        gender
      })
      .then(response => {
        if (response.data.success) {
          set_errorData(response.data.message);
        }
      })
      .catch(error => {
        set_errorData(error.response.data.message);
      });
  }

  function addToAPrintQueue() {
    const { name, lastName, phone } = Attendant;

    printQueueService
      .addToAPrintQueue({
        name: `${name} ${lastName}`,
        identification: phone
      })
      .then(response => {
        if (response.data.success) {
          Swal.fire(response.data.message);
        }
      })
      .catch(error => {
        Swal.fire(error.response.data.message);
      });
  }

  return (
    <section className="CheckIn">
      <Button primary onClick={() => resetForm()}>
        Atrás
      </Button>
      <h1 className="CheckIn__title">Asistente</h1>
      {showForm ? (
        <Form
          saveTag={'Actualizar'}
          Attendant={Attendant}
          handleInputs={handleInputs}
          handleDropDown={handleDropDown}
          errorInputs={{}}
          sendInForm
          showGender
          showAge
          createdAt
          submit={updateAttendant}
        />
      ) : (
        <div className="info">
          <p>
            <span>
              <b>Nombre:&nbsp;</b>
              {Attendant.name} {Attendant.lastName}
            </span>
            <span>
              <b>Cedula:&nbsp;</b>
              {Attendant.identification}
            </span>
            <span>
              <b>Edad:&nbsp;</b>
              {Attendant.age}
            </span>
          </p>
        </div>
      )}
      <Button
        className="modify"
        size="mini"
        onClick={() => set_showForm(!showForm)}
      >
        {showForm ? 'Ocultar' : 'Modificar'}
      </Button>
      <p style={{ color: 'red' }}>{errorData}</p>
      {currentUser.status.includes('events') && (
        <Button onClick={() => addToAPrintQueue()}>Imprimir escarapela</Button>
      )}
      <h2 className="CheckIn__subtitle">Asistencia</h2>
      {props.Attendant.reserved && (
        <EntryCheckpoint Attendant={Attendant} reserved={props.Attendant.reserved} set_errorData={set_errorData} />
      )}
    </section>
  );
};

export default GuestData;
