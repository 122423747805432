import React from 'react';
import 'react-dates/lib/css/_datepicker.css';
import Form from '../../../../components/form/Form';
import { Checkbox } from 'semantic-ui-react';
import { errorInput, formInput } from '../../../../components/helpers';
import { Input } from 'semantic-ui-react';

const ReservationStepFillData = props => {
  const { inputs, setInputs, setStep,secAudAv } = props;
  const [errorInputs, set_errorInputs] = React.useState({});
  const [loading, set_loading] = React.useState(false);

  const verifyForm = () => {
    let mandatoryInputs = [
      'name',
      'lastName',
      'email',
      'gender',
      'birthdate',
      'identification',
      'age',
      'phone'
    ];

    if (secAudAv) {
      mandatoryInputs = [
        ...mandatoryInputs,
        'parentIdentification',
        'parentFullname'
      ];
    }

    if (inputs.attendingGroup) {
      mandatoryInputs = [...mandatoryInputs, 'leaderName']
    }

    let errorInputs = {};

    mandatoryInputs.forEach(input => {
      errorInputs = errorInput({
        errorInputs,
        input: { name: input, value: inputs[input] }
      });
    });

    if (inputs['identification'] && inputs['identification'].length <= 5) {
      errorInputs = errorInput({
        errorInputs,
        input: {
          name: 'identification',
          value: inputs['identification'].length >= 5
        }
      });
    }

    if (inputs['phone'] && inputs['phone'].length <= 10) {
      errorInputs = errorInput({
        errorInputs,
        input: {
          name: 'phone',
          value: inputs['phone'].length >= 10
        }
      });
    }

    set_errorInputs(errorInputs);
    return Object.values(errorInputs).includes(true);
  };

  const nextStep = e => {
    e.preventDefault();
    if (verifyForm()) return;
    setStep('teCuidaInfo');
  };

  const handleInputs = event => {
    let value = event.target.value;
    let name = event.target.name;
    setInputs({ ...inputs, [name]: value });
  };

  const handleDropDown = ({ name, value }) => {
    setInputs({ ...inputs, [name]: value });
  };

  return (
    <form onSubmit={e => nextStep(e)} className="Registration__form">
      <h2>{inputs.createdAt ? 'Confirma tus datos' : 'Llena tus datos'}</h2>
      {Object.values(errorInputs).includes(true) && (
        <h4 style={{ color: 'red' }}>Por favor verifica los campos en rojo.</h4>
      )}
      <div style={{padding:"20px 0"}}>
        <Checkbox
          label="Vienes por 1a o 2a vez"
          name="newattendant"
          toggle
          checked={inputs.newattendant}
          style={{ padding: '0 10px' }}
          onClick={() => handleDropDown({name:'newattendant', value: !inputs.newattendant})}
        />
      </div>
      <div style={{padding:"20px 0"}}>
        <Checkbox
          label="¿Vas a grupo de conexión?"
          name="attendingGroup"
          toggle
          checked={inputs.attendingGroup}
          style={{ padding: '0 10px' }}
          onClick={() => handleDropDown({name:'attendingGroup', value: !inputs.attendingGroup})}
        />
      </div>
      {secAudAv && (
        <div>
          <p style={{margin:"0"}}>Información para menores de 14 años</p>
          {formInput({
            Input,
            handler: handleInputs,
            label: 'Nombre del acudiente',
            name: 'parentFullname',
            value: inputs.parentFullname || '',
            errorInputs
          })}
          {formInput({
            Input,
            handler: handleInputs,
            label: 'Cedula del acudiente',
            name: 'parentIdentification',
            value: inputs.parentIdentification || '',
            errorInputs,
            type: 'number'
          })}
        </div>
      )}
      <Form
        saveTag={'Continuar '}
        Attendant={inputs}
        userprofile={true}
        handleInputs={handleInputs}
        handleDropDown={handleDropDown}
        errorInputs={errorInputs}
        sendInForm
        showleaderName
        showGender
        showBirthdate
        loadedData={loading}
        submit={e => nextStep(e)}
      />
    </form>
  );
};

export default ReservationStepFillData;
